// File: /src/reducers/alertReducer.js
// Author: Harley McDonald
// Version 1.0
// This file holds our alert reducer.

// import the alert Types
import { SET_ALERT, REMOVE_ALERT, CLEAR_ALERT } from "../actions/types";

// Set up intial state.
const intialState = [];

// Set up the reducer.
export default function alertReducer(state = intialState, action)
{
  // Destructure payload and type from the action.
  const { payload, type } = action;
  switch(type){
    // Set alert case
    case SET_ALERT:
      return [...state, payload];
    // Remove alert case
    case REMOVE_ALERT:
      return state.filter(alert => alert.id !== payload);
    // Clear all alerts
    case CLEAR_ALERT:
      return {
        ...state,
        alert: undefined
      };
    default:
      return state;
  }
}