// File: client\src\components\information\About.js
// Author: Harley McDonald
// Version 1.0
// This file holds our about page.

//importing react and important components
import React, {useContext} from 'react';
//imports page images
import me from '../../Assets/img/20231113_104655.jpg'
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';

//our component
const About = () => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);
  return (
    <div className='container'>
      <h2 className={`${theme.theme.title} text-center p-3`}>About Me</h2>
      <div className={`p-1 m-4 ${theme.theme.compA} rounded`} style={{overflow: "hidden"}}>
        <img className='rounded float-end d-inline-block p-2' src={me} alt='A picture of me, Harley McDonald' style={{minWidth: "250px", maxWidth: "40%"}}/>
        <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>
        Hi, I'm Harley McDonald. I'm a programmer and have had an interest in programming since 2019, when I got my first proper taste working on a personal project in Lua for a Tabletop Simulator mod, starting with building digital counters into cards in a deck. A novel concept I got from a coding mod that injected a counter onto any object. Now you might say I just copied the code provided by that mod, simply applying it to the cards and being done with it. That was my initial idea even, something quick and easy, should have taken me half a day. But then I got bit by the coding bug.
        </p>
        <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>
        It started out with simply changing colours, making it so clicking on the counter incremented the count instead of a separate button. Then it was automating certain cards so that they incremented themselves, fixing bugs in the other code people had done for the board game I was modding. And then 2 months had passed and I was done, pushed to the limit of what my amateur knowledge could accomplish and I called my project done.
        </p>
        <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>
        I know that people have since taken that mod and updated it even further than I could take it at the time, and honestly I pity them a bit in hindsight. That mod, like a lot of amateur work, was a mess of half cobbled together code, wishes and dreams. No comments, no standards and whatever synergy someone had the fancy to implement, and little code baby Harley was just in the mix.
        </p>
        <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>
        But once I realised I'd so thoroughly enjoyed the project, and was amazed at how much I could focus on it with my ADHD, I knew it would be my path forward in the future. So I set about finding anyway to learn more. This took me to Holmesglen, the only institute with a course dedicated to programming, not just web design in my state. I jumped on the chance and finished the first semester with flying colours.
        </p>
        <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>
        Then come to the next year, 2020, everything started off great. Until COVID happened, and unprepared as everyone was at the time, I struggled with the sudden transition to remote learning. I had no time to learn the skills I needed to adapt to the new status quo, and knowing my limits, I postponed my learning while waiting out COVID and focusing on personal growth. Then, come mid 2021 I set my sights back on the course, but had to restart due to an unfortunately timed update to curriculum. From there I breezed through the certificate. I then took a semester break to build up my funds as I set my sights on the Diploma, hungry to improve my skills. 
        </p>
        <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>
        By mid 2022 I began my Diploma in earnest, improving my skills in the basics like Javascript and Python, PHP and MongoDB, just to name a few. Then come summer the institute ran a program I'd seen in years past, SummerTECHlive, a government sponsored program to connect learning programmers with small businesses that would otherwise struggle to employ digital solutions.
        </p>
        <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>
        I was offered a spot in the program on recommendation, something that took me by surprise as I was already planning to apply to the program, relishing in the chance to apply my developing skills. And over the three months I was connected with BigSMS Ltd, a small app development company. During this time I learnt a new framework, Flutter, based on Dart, and developed a comprehensive prototype with a wide suite of functions.
        </p>
        <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>
        From there I finished my Diploma in mid-2023 and now I find myself on the precipice of what I endeavour to make a wide ranging and fruitful career. With a drive to learn and improve fuelled by an intense interest in coding, rapid skill acquisition and a solid foundation in a wide range of languages and frameworks, I'm ready to take on any projects and expand my horizons.
        </p>
      </div>
    </div>
  )
}

//exports page
export default About;

