// File: client\src\components\information\Interests.js
// Author: Harley McDonald
// Version 1.0
// This file holds our interest page.

//importing react and important components
import React, {useState, useEffect, useContext} from 'react';
import { connect } from 'react-redux';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
//imports our get interests function
import { getInterests } from '../../actions/interestActions';
//imports single interest component
import SingleInterest from './SingleInterest';

//our component
const Interests = ({loading, getInterests, interests}) => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);
//variable used to track categories
  var i = 0;

  //sets an array of all existing categories to state
  const [categories] = useState([...new Set( interests.map(obj => obj.category)) ])

  //calls our get interests function
  useEffect(()=>{
    getInterests();
  },[getInterests])

  //displays loading until data has loaded, then displays page
  return loading ? (
    <h2 className={`${theme.theme.title} text-center p-3`}>Loading...</h2>
  ) : (
    <div className='container'>
        <h2 className={`${theme.theme.title} text-center p-3 m-3`}>Interest List</h2>
        <div className='m-4'>
          <table className={`table ${theme.theme.table} table-hover`}>
            <thead>
             <tr className='position-relative'>
                <th className={`${theme.theme.title} text-center align-middle col-3`}>Name</th>
                <th className={`${theme.theme.title} text-center align-middle col-6`}>About</th>
            </tr>
          </thead>
          <tbody className='table-group-divider'>
            {interests.map(interest => {
              //checks if a new category needs to be displayed and then moves along the array
              if(interest.category === categories[i]){
              i++;
              //adds both category row and triggering interest
              return <><tr><td className={`${theme.theme.title} text-center align-middle col-12`} colSpan="2">{categories[(i-1)]}</td></tr><SingleInterest interest={interest}/> </>
            } //otherwise just displays interest
              return  <SingleInterest interest={interest}/> 
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

// Define the props that our component will get.
Interests.propTypes = {
  interests: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getInterests: PropTypes.func.isRequired,
}

// Define mapStateToProps.
const mapStateToProps = state => ({
  interests: state.interests.interests,
  loading: state.interests.loading
});

//exports page with props and functions
export default connect(mapStateToProps, {getInterests})(Interests);