// File: client\src\App.js
// Author: Harley McDonald
// Version 1.0
// This file holds our app component.

//importing react and important components
import React from 'react';
// imports our router in distinguisable names
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//imports our css
import './App.css';
//import axios to set base url
import axios from 'axios';

// Import Custom Components.
import Alert from './components/layout/Alert';
import Header from './components/layout/header/Header';
import Footer from './components/layout/Footer';
import Home from './components/basic info/Home';
import Privacy from './components/basic info/Privacy';
import Experience from './components/information/Experience';
import About from './components/information/About';
import Project from './components/information/Project';
import Interests from './components/information/Interests';
import ManageUsers from './components/manangement/users/ManageUsers.js';
import Profile from './components/manangement/users/Profile';
import EditInterests from './components/manangement/EditInterests';
import ManageProject from './components/manangement/ManageProject';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import setAuthToken from "./utils/setAuthToken";

//our app component
const App = () => {
  if(localStorage.token){
    setAuthToken(localStorage.token);
  }

  axios.defaults.baseURL = "https://harleyportfoliodb.azurewebsites.net";
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
  
  
  return (
      <Router>
        <Header/>
          <Alert />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='users/login' element={<Login />} />
            <Route path='users/register' element={<Register />} />
            <Route path='users/profile' element={<Profile />} />
            <Route path='manage/users' element={<ManageUsers />} />
            <Route path='manage/project' element={<ManageProject />} />
            <Route path='manage/interests' element={<EditInterests />} />
            <Route path='info/about' element={<About />} />
            <Route path='info/project' element={<Project />} />
            <Route path='info/interests' element={<Interests />} />
            <Route path='info/experience' element={<Experience />} />
          </Routes>
        <Footer />
      </Router>
  );
}

//exports our component
export default App;
