// File: client\src\components\manangement\SingleEditProject.js
// Author: Harley McDonald
// Version 1.0
// This file holds our edit project component.

//importing react and important components
import React, {useState, useContext} from 'react';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
// import connect
import { connect } from 'react-redux';
// import icons
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
// import useNavigate
import {useNavigate} from 'react-router-dom';
// import Delete function.
import { deleteProject, updateProject } from '../../actions/projectActions';
//imports a component for framing modal content
import ModalFrame from '../layout/ModalFrame';
//imports a component for creating modals
import ReactModal from 'react-modal';

//our component
const SingleEditProject = ({project, updateProject, deleteProject, i}) => {

  //grabs our theme context from the surface level component
  const theme =  useContext(ThemeContext);
  
  //destructures the project state
  const { title, type, lang, time, desc, hiLit, satisf, selfRat, takeAway, projectId } = project;

  //sets our navigate variable
  const navigate = useNavigate();
  
  //Our toggle for displaying our modal form
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  //adds our form data to the state
  const [formData, setFormData] = useState({
    projectId: projectId,
    fTitle: "",
    fType: "",
    fLang: "",
    fTime: "",
    fDesc: "",
    fHiLit: "",
    fSatisf: "",
    fSelfRat: "",
    fTakeAway: "",
    errors: {}
  });

  // Destructure the state.
  const {fTitle, fType, fLang, fTime, fDesc, fHiLit, fSatisf, fSelfRat, fTakeAway, errors } = formData;

  // On Change
  const onChange = e => setFormData(
    { ...formData, [e.target.name]: e.target.value }
  );

  // onSumbit function
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log('On sumbit - ManageProject page');

    // Error checking
    var errorChk = false;
    var err = {};
    
    // Check errors title
    if (fTitle === ''){
      err.fTitle = 'Project Title is required ';
      errorChk = true;
    }
    
    // Check errors type
    if (fType === ''){
      err.fType = 'Project Type is required ';
      errorChk = true;
    }
    
    // Check errors base
    if (fLang === ''){
      err.fLang = 'Project Base is required ';
      errorChk = true;
    }
    
    // Check errors duration
    if (fTime === ''){
      err.fTime = 'Project Duration is required ';
      errorChk = true;
    }
    
    // Check errors description
    if (fDesc === ''){
      err.fDesc = 'Description is required ';
      errorChk = true;
    }

    // Check errors highlight
    if (fHiLit === ''){
      err.fHiLit = 'Highlight of project is required';
      errorChk = true;
    }

    // Check errors satisfaction
    if (fSatisf === ''){
      err.fSatisf = 'Satisfaction rating of project is required';
      errorChk = true;
    }

    // Check errors self rating
    if (fSelfRat === ''){
      err.fSelfRat = 'Self Rating of work on project is required';
      errorChk = true;
    }

    // Check errors lesson learned
    if (fTakeAway === ''){
      err.fTakeAway = 'Lesson learned from project is required';
      errorChk = true;
    }

    //returns all errors
    if(errorChk){
      setFormData({...formData, errors});
      return;
    }
    // Create the user object
    const projectSub = { projectId, title: fTitle, type: fType, lang: fLang, time: fTime, desc: fDesc, hiLit: fHiLit, satisf: fSatisf, selfRat: fSelfRat, takeAway: fTakeAway};
    console.log(projectSub);
    // Call the register function
    await updateProject(projectSub);
    // redirect the browser
    navigate('/info/project');
  }
  return (
    <table className={`p-1 table ${theme.theme.table} table-hover rounded`} style={{overflow: "hidden"}}>
    <tbody>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Project Title: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{title}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Type: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{type}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Components: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{lang}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Duration: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{time}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Description: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{desc}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Highlight: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{hiLit}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Satisfaction Level: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{satisf}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Self Rating: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{selfRat}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Lesson Learned: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{takeAway}</td></tr>
    <tr><td></td><td className={`${theme.theme.title} text-center align-middle col-3`} >
      <FaPencilAlt className='text-primary p-1' onClick={toggleModal} style={{cursor: 'pointer' }} title="Edit"  size={50}/>
      <FaTimes className='text-danger p-1' size={60} onClick={e => deleteProject(projectId)} style={{ cursor: 'pointer'}} title='Delete'/>
      <ReactModal isOpen={modalOpen} onRequestClose={toggleModal} className={`${theme.theme.body} position-absolute top-50 start-50 translate-middle w-50`}>
        <ModalFrame close={toggleModal}>
          <div>
          <form onSubmit={e => onSubmit(e)}>
            <input type="hidden" id="projectId" name="projectId" value={projectId}></input>

            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='fTitle'>Project Title</label>
              <input 
                type='text'
                className={`form-control ${errors.fTitle ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='fTitle'
                placeholder='Please type out the title of the project'
                name='fTitle'
                value={`${fTitle === '' ? title : fTitle}`} 
                onChange={e => onChange(e)}
              />
              {errors.fTitle && <div className='invalid-experience'>{errors.fTitle}</div>}
            </div>

            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='fType'>Type</label>
              <input 
                type='text'
                className={`form-control ${errors.fType ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='fType'
                placeholder='Please type the type of project, ect Professional'
                name='fType'
                value={`${fType === '' ? type : fType}`} 
                onChange={e => onChange(e)}
              />
              {errors.fType && <div className='invalid-experience'>{errors.fType}</div>}
            </div>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='fLang'>Project Components</label>
              <input 
                type='text'
                className={`form-control ${errors.fLang ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='fLang'
                placeholder='Please type the list of components that made up the project, ect languages, frameworks'
                name='fLang'
                value={`${fLang === '' ? lang : fLang}`} 
                onChange={e => onChange(e)}
              />
              {errors.fLang && <div className='invalid-experience'>{errors.fLang}</div>}
            </div>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='fTime'>Project Duration</label>
              <input 
                type='text'
                className={`form-control ${errors.fTime ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='fTime'
                placeholder='Please type how long you worked on the project in years'
                name='fTime'
                value={`${fTime === '' ? time : fTime}`} 
                onChange={e => onChange(e)}
              />
              {errors.fTime && <div className='invalid-experience'>{errors.fTime}</div>}
            </div>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='fDesc'>Description</label>
              <textarea
                className={`form-control ${errors.fDesc ? "is-invalid" : 'is-valid'} ${theme.theme.compA}`}
                id='fDesc'
                placeholder='Please type out a description of the project'
                name='fDesc'
                value={`${fDesc === '' ? desc : fDesc}`} 
                onChange={e => onChange(e)}
                rows={10}
              />
              {errors.fDesc && <div className='invalid-experience'>{errors.fDesc}</div>}
            </div>

            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='fHiLit'>Highlight</label>
              <textarea
                className={`form-control ${errors.fHiLit ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='fHiLit'
                placeholder='Please describe a highlight of the project'
                name='fHiLit'
                value={`${fHiLit === '' ? hiLit : fHiLit}`} 
                onChange={e => onChange(e)}
              />
              {errors.fHiLit && <div className='invalid-experience'>{errors.fHiLit}</div>}
            </div>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='fSatisf'>Satisfaction</label>
              <input 
                type='text'
                className={`form-control ${errors.fSatisf ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='fSatisf'
                placeholder='Please type your level of satisfaction with the project'
                name='fSatisf'
                value={`${fSatisf === '' ? satisf : fSatisf}`} 
                onChange={e => onChange(e)}
                rows={10}
              />
              {errors.fSatisf && <div className='invalid-experience'>{errors.fSatisf}</div>}
            </div>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='fSelfRat'>Self Rating</label>
              <input 
                type='text'
                className={`form-control ${errors.fSelfRat ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='fSelfRat'
                placeholder='Please give yourself a rating on your work throughout this project out of 5'
                name='fSelfRat'
                value={`${fSelfRat === '' ? selfRat : fSelfRat}`} 
                onChange={e => onChange(e)}
              />
              {errors.fSelfRat && <div className='invalid-experience'>{errors.fSelfRat}</div>}
            </div>

            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='fTakeAway'>Takeaway</label>
              <textarea
                className={`form-control ${errors.fTakeAway ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='fTakeAway'
                placeholder='Please describe a lesson you learned from this project.'
                name='fTakeAway'
                value={`${fTakeAway === '' ? takeAway : fTakeAway}`} 
                onChange={e => onChange(e)}
                rows={10}
              />
              {errors.fTakeAway && <div className='invalid-experience'>{errors.fTakeAway}</div>}
            </div>

            <div className='d-grid gap-2'>
              <input type='submit' value='Submit' className={`${theme.theme.btn}`}/>
            </div>
          </form>
          </div>
        </ModalFrame>
        </ReactModal>
      </td></tr>
    </tbody>
    </table>
  )
}

// Prop type checking
SingleEditProject.propTypes = {
  project: PropTypes.object.isRequired,
  deleteProject: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
}

//exports our component
export default connect(null, {deleteProject, updateProject})(SingleEditProject);