// File: /src/actions/interestActions.js
// Author: Harley McDonald
// Version 1.0
// This file holds all our actions for interests.

// import types
import {
  GET_INTERESTS,
  GET_INTEREST,
  ADD_INTEREST,
  UPDATE_INTEREST,
  DELETE_INTEREST,
  DEL_ERROR
} from './types';

//import axois
import axios from 'axios';

//imports the set alert function for relaying errors
import { setAlert } from './alertActions';

//gets all our interests
export const getInterests = () => async dispatch => {
  console.log('Get Interests');

  try {
    // Call the api to get all interests
    const res = await axios.get('/api/interests');
    // Dispatch to the reducer
    dispatch({
      type: GET_INTERESTS,
      payload: res.data
    });
  } catch (error) {
    /*const errors = error.response.data.errors;
    console.log(errors);
    if(errors){
      errors.array.forEach(err => dispatch(setAlert(err.msg, 'danger')));
    }*/
    dispatch({
      type: GET_INTERESTS,
      payload: [
        /*Template {interestId: 0, name: "",
        about: "",
        category: ""},*/
        {interestId: 1, name: "Tabletop Roleplaying Games(TTRPGs)", 
        about: "I regularly engage group sessions using TTRPGs, both as a Game Master(Lead) and Player(Team Member). I have even created a whole host of custom content for these games, particularly the ones I run. I have even helped create and design custom systems for personal use.", 
        category: "Hobby"},
        {interestId: 2, name: "Board Games",
        about: "I on occasion enjoy playing a large variety of board games, from classic Euro's like Settler's of Catan to more modern games like Terraforming Mars",
        category: "Hobby"},
        {interestId: 3, name: "Physics",
        about: "Ever since I was a young teen I've been facinated by the world we live in, the greater universe and how it all works. So until this day I tend to keep up with as much phsyics news as I can.",
        category: "Academic"},
        {interestId: 4, name: "Chemistry",
        about: "While not as strong as my interest in physics, I enjoy learning about interesting chemical facts andthings such as the exploration of new elements and the chemical side of medications.",
        category: "Academic"},
        {interestId: 5, name: "Database Management",
        about: "In the time that I spent learning to program I naturally used a lot of databases, from this I came to realise that the structure and function of a well built database can be very satisfying to create.",
        category: "Professional"},
        {interestId: 6, name: "Video Games",
        about: "As a lot of people in this day and age do, I enjoy playing video games in my spare time. Personally I play different games for different reasons, but a few things that I really enjoy are games where I can act efficiently or build efficient systems, stratergy games particularly. Additionally I do enjoy occasionally breaking games and pushing them past their limits.",
        category: "Hobby"},
        {interestId: 7, name: "Bug Fixing",
        about: "Most programmers hate code bugs with either a passion or disdain, I am the former. Every problem with some code I come across is like a little puzzle to pick apart, explore, learn and overcome.",
        category: "Professional"},
        {interestId: 8, name: "Optimisation",
        about: "We have all, as programmers, made code that is rough and ram shock in our days, particularly our early ones and when working on a prototype. This code is sometimes necessary to get off the ground, but when you have the base working nothing feels better when you figure out a more elegant and streamlined solution.",
        category: "Professional"},
        {interestId: 9, name: "History",
        about: "I quite enjoy learning about our past and the lessons we can learn from it, from pre-historic humans, to ancient civilisations and even recent decades. There's a lot to learn from and head the warning of in history when you listen.",
        category: "Academic"},  
        {interestId: 10, name: "Writing",
        about: "In my spare time I like to write from time to time. Whether its a short story, the plot of a game or novel or even ideas for new mechanics for my TTRPG games, writing is a part of who I am.",
        category: "Hobby"},
        {interestId: 11, name: "Learning",
        about: "If the other interests aren't enough of an indication, I plainly enjoy learning new and interesting things about the world and things in it. If I learn something new every day, I'll be happy for the rest of my life.",
        category: "General"}]
    });
  }
};

//gets a single interest, deprecated
export const getInterest = (id) => async dispatch => {
  //call api to get single interest
  const res = await axios.get(`/api/interests/edit/${id}`);
  ///dispatch
  dispatch({
    type: GET_INTEREST,
    payload: res.data
  });
};

//adds new interest to db
export const addInterest = (interest) => async dispatch => {
  // call the api
  const res = await axios.post('/api/interests/add', interest);
  // dispatch action and payload.
  dispatch({
    type: ADD_INTEREST,
    payload: res.data
  });
};

//updates an existing interest
export const updateInterest = (interest) => async dispatch => {
  // call the api
  const res = await axios.put(`/api/interests/edit/${interest.interestId}`, interest);
  // dispatch action and payload.
  dispatch({
    type: UPDATE_INTEREST,
    payload: res.data
  })
  dispatch(getInterests());
};

//deletes an existing interest
export const deleteInterest = (id) => async dispatch => {
  // call api
  try {
    await axios.delete(`/api/interests/edit/${id}`);
    // Dispatch
    dispatch({
      type: DELETE_INTEREST,
      payload: id
    })

    dispatch(getInterests());
  } catch (error) {
    dispatch({
      type: DEL_ERROR,
      payload: error
    })
  }
}

