// File: src\components\auth\Login.js
// Author: Harley McDonald
// Version 1.0
// This file holds our login page.

//importing react and important components
import React, {Fragment, useState, useContext} from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
// bring in the login function from our actions file
import { login } from '../../actions/authActions';

//defines our componnent
const Login = ({ login, isAuthenticated }) => {

  //grabs our theme context from the surface level component
  const theme =  useContext(ThemeContext);

  // set up the state for our login form.
  const [ formData, setFormData] = useState({
    email: '',
    password: '',
    errors: {}
  });

  // Destructure our form state.
  const { email, password, errors } = formData;

    // Create our navigate var.
    const navigate = useNavigate();

  // onChange function.
  const onChange = e => setFormData({
    ...formData, [e.target.name]: e.target.value
  });

  // onSubmit 
  const onSubmit = async (e) => {
    // prevent refresh
    e.preventDefault();

    // Error checking
    var errorChk = false;
    var err = {};

    // check for errors in the email field
    if (email === ''){
      err.email= 'Email is required ';
      errorChk = true;
    }

    // Check for errors in the password field
    if (password === ''){
      err.password= 'Password is required ';
      errorChk = true;
    }

    //returns all errors
    if(errorChk){
      setFormData({...formData, errors});
      return;
    }

    // create a user object to send to our login function.
    const user = { email, password};
    // run the login function with our user object.
    await login(user);
    // We can use the useNavigate hook to redirect the browser after we have successfully logged in.
    navigate('/');
  }

  //checks if user is logged in and if so, automatically redirects them
  return isAuthenticated === true ? (
    <Navigate to='/'/>
  ) : (
    <Fragment>
      <div className='container' style={{minWidth: "450px", maxWidth: '30%'}}>
      <h1 className={`${theme.theme.title} text-center p-3`}>Login</h1>
      <div className={`card mb-3 ms-2 me-2 ${theme.theme.compA}`}>
        <div className={`card-header ${theme.theme.title} ${theme.theme.compA}  ${theme.theme.text}`}>
          Login (Currently disabled due to server issues)
        </div>
        <div className={`card-body ${theme.theme.compC} `}>
          {/* Change onSubmit call */}
          <form onSubmit={e => onSubmit(e)}>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='email'>Email</label>
              <input 
                type='text'
                className={`form-control ${errors.email ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='email'
                placeholder='Email'
                name='email'
                value={email} 
                disabled= "disabled"
                onChange={e => onChange(e)}
              />
              {errors.email && <div className='invalid-experience'>{errors.email}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='password'>Password</label>
              <input 
                type="password"
                className={`form-control ${errors.password ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='password'
                placeholder='password'
                name='password'
                value={password}
                disabled= "disabled"
                onChange={e => onChange(e)}
              />
              {/* Error message rendering */}
              {errors.password && <div className='invalid-experience'>{errors.password}</div>}
            </div>
            <div className='d-grid gap-2'>
              <input type='submit' value='Login' disabled= "disabled" className={`${theme.theme.btn}`}/>
            </div>
          </form>
        </div>
        <p className={`${theme.theme.text} m-1`}>
          Don't have an account? <Link className={`${theme.theme.link} `} to='/users/register'>Sign Up</Link>  
        </p>
      </div>
      </div>
    </Fragment>
  )
}

// Add in proptypes
Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
}

// Map State to props.
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

// add in connect
export default connect(mapStateToProps, { login })(Login);