// File: /src/actions/userActions
// Author: Harley McDonald
// Version 1.0
// This file holds all our actions for users.

// import types
import {
  GET_USERS,
  GET_USER,
  GET_SELF,
  GET_ALL,
  UPDATE_USER,
  UPDATE_SELF,
  DELETE_USER,
  DELETE_SELF,
  DEL_ERROR,
} from './types';
//import axois
import axios from 'axios';
//imports the set alert function for relaying errors
import { setAlert } from './alertActions';
//imports the logout dispatch from auth for use in delete self
import { logout } from './authActions';

//gets all existing users without password, used by admin
export const getUsers = () => async dispatch => {
  console.log('Get Users');

  try {
    // Call the api
    const res = await axios.get('/api/users');
    // Dispatch to the reducer
    console.log(res.data);
    dispatch({
      type: GET_USERS,
      payload: res.data
    });
  } catch (error) {
    /*const errors = error.response.data.errors;
    console.log(errors);
    if(errors){
      errors.array.forEach(err => dispatch(setAlert(err.msg, 'danger')));
    }*/
    
    dispatch({
      type: GET_USERS,
      payload: [
        {userId: 0, isAdmin: 0},
        {userId: -1, isAdmin: 0}
      ]
    });
  }
};

//gets a single user, deprecated
export const getUser = (id) => async dispatch => {
  //call the api
  const res = await axios.get(`/api/users/edit/${id}`);
  //dispatch
  dispatch({
    type: GET_USER,
    payload: res.data
  });
};

//gets the user's profile using it's token
export const getSelf = () => async dispatch => {
  //calls the api
  const res = await axios.get(`/api/users/selfEdit`);
  //dispatch
  dispatch({
    type: GET_SELF,
    payload: res.data
  });
};

//gets all users without sensitive data, used for experience
export const getAll = () => async dispatch => {
  try{
    const res= await axios.get('/api/users/all');

    dispatch({
      type: GET_ALL,
      payload: res.data
    });
  }
  catch(error){
    dispatch({
      type: GET_ALL,
      payload: [
        {userId: 1, isAdmin: 0},
        {userId: 2, isAdmin: 0}
      ]
    });
  }
};

//updates a user, used by admin
export const updateUser = (user) => async dispatch => {
  // call the api
  const res = await axios.post(`/api/users/edit/${user.userId}`, user);
  // dispatch action and payload.
  dispatch({
    type: UPDATE_USER,
    payload: res.data
  })
};

//updates user's profile, requires password confirmation
export const updateSelf = (user) => async dispatch => {
  // call the api
  const res = await axios.post(`/api/users/selfEdit`, user);
  // dispatch action and payload.
  dispatch({
    type: UPDATE_SELF,
    payload: res.data
  })
};

//deletes a user, all authored content are asigned to the default deleted user
export const deleteUser = (id) => async dispatch => {
  // call api
  try {
    await axios.delete(`/api/users/edit/${id}`);
    // Dispatch
    dispatch({
      type: DELETE_USER,
      payload: id
    })
  } catch (error) {
    dispatch({
      type: DEL_ERROR,
      payload: error
    })
  }
}

//deletes the user's own account, requires password confirmation
export const deleteSelf = (user) => async dispatch => {
  // call api
  try {
    await axios.delete(`/api/users/selfEdit`, user);
    // Dispatch
    dispatch({
      type: DELETE_SELF
    })
    dispatch(logout())
  } catch (error) {
    dispatch({
      type: DEL_ERROR,
      payload: error
    })
  }
}

