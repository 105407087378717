// File: client\src\components\layout\header\NavIcon.js
// Author: Harley McDonald
// Version 1.0
// This file holds our menu icons.

//importing react and important components
import React from 'react'
// Import the icons we wish to use.
import { FaHome, FaUserCog, FaQuestion, FaSignInAlt, FaSignOutAlt, FaUsers, FaBurn, FaRegSun, FaClipboardList, FaColumns, FaComment, FaUser, FaUserEdit, FaInfo, FaWrench, FaBookOpen  } from 'react-icons/fa'

//nav icon component that returns the apporpriate icon
const NavIcon = (title) => {
  //checks the input to determine icon
  switch (title.title){
    case 'Home':
      return (
        <FaHome/>
      )
    case 'Experiences':
      return (
        <FaComment/>
      )
    case 'About':
      return (
        <FaColumns/>
      )
    case 'Interests':
      return (
        <FaBurn/>
      )
    case 'Projects':
      return (
        <FaClipboardList/>
      )
    case 'Profile':
      return (
        <FaUserCog/>
      )
    case 'Manage Users':
      return (
        <FaUsers/>
      )
    case 'Interests Management':
      return (
        <FaWrench/>
      )
    case 'Project Management':
      return (
        <FaBookOpen/>
      )
    case 'Login':
      return (
        <FaSignInAlt/>
      )
    case 'Register':
      return (
        <FaUserEdit/>
      )
    case 'User':
      return (
        <FaUser/>
      )
    case 'Info':
      return (
        <FaInfo/>
      )
    case 'Website Management':
      return (
        <FaRegSun/>
      )
    case 'Logout':
      return (
        <FaSignOutAlt/>
      )
    default:
      return (
        <FaQuestion/>
      )
  }
}

//exports our component
export default NavIcon