// File: client\src\components\layout\header\Hamburger.js
// Author: Harley McDonald
// Version 1.0
// This file holds our hamburger menues.

//importing react and important components
import React from 'react';
//object containing data for all nav items
import {menuItems} from '../../dynamics/menuItems';
//imports our menu items component
import MenuItems from './MenuItems';

//our component
const Hamburger = () =>{
  return (
      <div className="hamburger">
        <ul className="navbar-nav ms-auto">
          {menuItems.map((menu, index) => {
            //builds our menu from menuItems
            return <MenuItems items={menu} key={index} />;
          })}
        </ul>
      </div>
    )
}

//exports our component
export default Hamburger;