// File: /src/components/layout/Footer.js
// Author: Harley McDonald
// Version 1.0
//This file contains our footer component

//importing react and important components
import React, {useState, useContext} from 'react';
//import icons
import { FaMoon, FaSun } from 'react-icons/fa';
//imports links
import { Link } from 'react-router-dom';
//imports our theme context for toggling
import { ThemeContext, themes } from '../dynamics/ThemeContext';

//our component
const Footer = () => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);
  // Use JavaScript date to create a date obejct.
  const date = new Date();
  
  //sets a bool to state to check dark mode toggle
  const [darkMode, setDarkMode] = useState(true);

  return (
    <div className={`${theme.theme.bar} p-2 my-3 row w-100`}>
      <div className='col-4 ps-5 pt-3'>
        <ThemeContext.Consumer>
        {({ changeTheme }) => (
            <Link
              color="link"
              onClick={() => {
                setDarkMode(!darkMode);
                changeTheme(darkMode ? themes.light : themes.dark);
              }}
            >
              <i className="">{darkMode ? <FaSun className='text-warning' size={30}></FaSun> : <FaMoon className='text-light' size={30}></FaMoon>}</i>
            </Link>
          )}
        </ThemeContext.Consumer>
      </div>
      <div className='col-4'>
        <p className='py-2 pt-3 text-white text-center'>
          Contact us at: <br/>
          email: Harley.S.McDonald@outlook.com<br/>
          ph: 0468967371
        </p>
      </div>
      <div className='col-4'>
      <p className='py-2 pt-3 text-white text-end'>
        copyright { String.fromCharCode(169)}
        { ' ' + date.getFullYear()} Harley McDonald
      </p>
      </div>
    </div>
  )
}

//exports our component
export default Footer;
