// File: src/utils/setAuthToken.js
// This file will set our toke to be sent in our http headers whenever we send a http request from our client side.

// Import axios.
import axios from 'axios';

// Create our function. This function will take in a token.
const setAuthToken = token => {
// Check if we have a token?
  if(token){
    // If we do have a token, add the token to our axios requests in the http header, under the property 'x-auth-token'.
    // This will create the key value piar 'x-auth-token': token.
    axios.defaults.headers.common['x-auth-token'] = token;
  } else {
    // if the token is not there, remove x-auth-token from our headers.
    delete axios.defaults.headers.common['x-auth-token'];
  }
}

// Export our function.
export default setAuthToken;