// File: src/reducers/experienceReducer.js
// Author: Harley McDonald
// Version 1.0
// This file holds all our state and reducers that will deal with experience.

// Import our types.
import {
  GET_EXPERIENCE,
  GET_SINGLE_EXPERIENCE,
  ADD_EXPERIENCE,
  UPDATE_EXPERIENCE,
  DELETE_EXPERIENCE,
  DEL_ERROR
} from '../actions/types';

// Create our initial state object.
const initialState = {
  experienceList: [],
  experience: {},
  loading: true,
  errors: {}
};

// Create our experienceReducer to change our state.
// A reducer takes in the state and an action.
// This will update the state based on the action dispatched.
export default function experienceReducer(state = initialState, action){
  switch(action.type){
    // The cade to get all experience
    case GET_EXPERIENCE:
      return {
        ...state,
        experienceList: action.payload || [],
        loading: false
      }
    // Getting single experience (getting experience by id).
    case GET_SINGLE_EXPERIENCE:
      return {
        ...state,
        experience: action.payload,
        loading: false
      }
    // case for adding experience
    case ADD_EXPERIENCE:
      return{
        ...state,
        experienceList: [action.payload, ...state.experienceList],
        loading: false
      };
      case UPDATE_EXPERIENCE:
        return{
          ...state,
          experienceList: state.experienceList.map (
            item => item.expId === action.payload.expId ?
            (item = action.payload) : item
          ),
          loading: false
        };
    // a case to delete experience
    case DELETE_EXPERIENCE:
      return {
        ...state,
        experienceList: state.experienceList.filter(
          item => item.expId !== action.payload
        ),
        loading: false
      };
    // Case for DEL_ERROR
    case DEL_ERROR:
      return{
        ...state,
        errors: action.payload
      };
    default:
      return state;
  }
}