// File: client\src\components\manangement\SingleEditInterest.js
// Author: Harley McDonald
// Version 1.0
// This file holds our edit interest component.

//importing react and important components
import React, {useState, useContext} from 'react';
import { connect } from 'react-redux';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
//imports
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
//imports a component for creating modals
import ReactModal from 'react-modal';
//imports a component for framing modal content
import ModalFrame from '../layout/ModalFrame';
// import delete and edit functions.
import { updateInterest, deleteInterest } from '../../actions/interestActions';

//our component
const SingleEditInterest = ({ interest, updateInterest, deleteInterest, categories }) => {
  
  //grabs our theme context from the surface level component
  const theme =  useContext(ThemeContext);

  //destructures the interest 
  const { interestId, name, category, about } = interest;
  
  //Our toggle for displaying our modal form
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  //adds our form data to the state
  const [formData, setFormData] = useState({
    interestId: interestId,
    fName: "",
    fCategory: "",
    newCategory: "",
    fAbout: "",
    errors: {}
  });

  // Destructure the state.
  const { fName, fCategory, newCategory, fAbout, errors } = formData;
  
  // On Change
  const onChange = e => { setFormData({ ...formData, [e.target.name]: e.target.value })};
  

  // onSumbit function
  const onSubmit = async (e) => {
    e.preventDefault();
    var finalCat;
    // Error checking
    var errorChk = false;
    var err = {};

    // Check errors interest Name
    if (fName === ''){
      err.name = 'Interest Name is required';
      errorChk = true;
    }
    // Check errors for category
    if (fCategory === '' && newCategory === ''){
      err.category = 'Category is required';
      errorChk = true;
    }
    else{//else set the final category, prioritising the new category
      if(newCategory === ''){
        finalCat = fCategory;
      }
      else{
        finalCat = newCategory;
      }
    }
    // Check errors about
    if (fAbout === ''){
      err.about = 'About is required';
      errorChk = true;
    }

    //returns all errors
    if(errorChk === true){
      console.log()
      setFormData({...formData, errors: err});
      return;
    }

    // Create the interest object
    const editS = { interestId, name: fName, category: finalCat, about: fAbout};
    console.log(editS);

    // Call the register function
    await updateInterest(editS);

    //closes the modal
    setModalOpen(false);
  }
  return (
    <tr>
    <td className={`${theme.theme.text}  text-center align-middle col-3`}>{name}</td>
    <td className={`${theme.theme.text}  text-center align-middle col-6`}>{about}</td>
    <td className={`${theme.theme.text}  text-center align-middle col-1`}>
      <FaPencilAlt className='text-primary p-1' onClick={toggleModal} style={{cursor: 'pointer' }} title="Edit"  size={35}/>
      <ReactModal isOpen={modalOpen} onRequestClose={toggleModal} className={`${theme.theme.body} position-absolute top-50 start-50 translate-middle w-50`}>
        <ModalFrame close={toggleModal}>
          <div>
          <form onSubmit={e => onSubmit(e)}>
            <div className={`mb-3`}>
              <input type="hidden" id="interestId" name="interestId" value={interestId}></input>
              <label className={`${theme.theme.text} `}  htmlFor='fName'>Interest Name</label>
              <input 
                type='text'
                className={`form-control ${errors.name ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='fName'
                placeholder='Interest Name'
                name='fName'
                value={`${fName === '' ? name : fName}`} 
                onChange={e => onChange(e)}
              />
              {errors.name && <div className='invalid-experience'>{errors.name}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='category'>Category</label>
              <select id='fCategory' name='fCategory' onChange={e => onChange(e)} >
                <option value="" >Please Select a Category</option>
                {categories.map(cat => ( fCategory === "" ? (category === cat ? <option value={cat} selected>{cat}</option> : <option value={cat}>{cat}</option>) : (fCategory === cat ? <option value={cat} selected>{cat}</option> : <option value={cat}>{cat}</option>)))}
              </select>
              {errors.category && <div className='invalid-experience'>{errors.category}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='newCategory'>New Category</label>
              <input
                className={`form-control ${errors.newCategory ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='newCategory'
                placeholder='New Category'
                name='newCategory'
                value={newCategory} 
                onChange={e => onChange(e)}
              />
              {errors.newCategory && <div className='invalid-experience'>{errors.newCategory}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='fAbout'>About</label>
              <textarea
                className={`form-control ${errors.about ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='fAbout'
                placeholder='About'
                name='fAbout'
                value={`${fAbout === "" ? about : fAbout}`} 
                onChange={e => onChange(e)}
              />
              {errors.about && <div className='invalid-experience'>{errors.about}</div>}
            </div>
            <div className='d-grid gap-2'>
              <input type='submit' value='Submit' className={`${theme.theme.btn}`}/>
            </div>
          </form>
          </div>
        </ModalFrame>
      </ReactModal>
    </td>
    <td className={`${theme.theme.text}  text-center align-middle col-1`}>
      <FaTimes className='text-danger p-1' size={40} onClick={e => deleteInterest(interestId)} style={{ cursor: 'pointer'}} title='Delete'/>
    </td>
  </tr>
  )
}

//checks prop types
SingleEditInterest.propTypes = {
  interest: PropTypes.object.isRequired,
  updateInterest: PropTypes.func.isRequired,
  deleteInterest: PropTypes.func.isRequired
}

//exports our page
export default connect(null, {updateInterest, deleteInterest})(SingleEditInterest);