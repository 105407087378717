// File: client\src\components\information\SingleInterest.js
// Author: Harley McDonald
// Version 1.0
// This file holds our single interest component.

//importing react and important components
import React, {useContext} from 'react';
import { connect } from 'react-redux';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';

const SingleInterest = ( { interest} ) => {
  //grabs our theme context from the surface level component
  const theme =  useContext(ThemeContext);

  //destructures the interest
  const { name, about} = interest;
  return (
    <tr>
    <td className={`${theme.theme.text}  text-center align-middle col-3`}>{name}</td>
    <td className={`${theme.theme.text}  text-center align-middle col-6`}>{about}</td>
  </tr>
  )
}

// Prop type checking
SingleInterest.propTypes = {
  interest: PropTypes.object.isRequired
}

// exports our component
export default connect(null)(SingleInterest);