// File: client\src\components\information\Project.js
// Author: Harley McDonald
// Version 1.0
// This file holds our project page.

//importing react and important components
import React, { useEffect, useContext} from 'react';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
// Import connect from react-redux.
import { connect } from 'react-redux';
// import our singleProject component.
import SingleProject from './SingleProject';
// import our getProject function from the actions file
import { getProject } from '../../actions/projectActions';

// Props destructuring.
const Project = ({ loading, project, getProject}) => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);
//used to alternate between component colouring
  var i = 1;

  // This will use our getProject action.
  useEffect(() => {
    getProject();
  }, [getProject]);

  //displays loading until data has loaded, then displays page
  return (loading) ? (
    <h2 className={`text-center ${theme.theme.title} p-3`}> Loading... </h2>
  ) : (
    <div className='container'>
      <h2 className={`text-center ${theme.theme.title} p-3 mt-3`}>Projects</h2>
      <div className='m-5'>
      {//loops through all project posts
        project.map(projectPost => {
          i++;
          //displays project post
          return <div className='py-3'><SingleProject project={projectPost} i={i}/></div>
        })
      }
      </div>
    </div>
  )
}

// Define the props that our component will get.
Project.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.array.isRequired
};

// Define mapStateToProps.
const mapStateToProps = state => ({
  project: state.project.project,
  loading: state.project.loading
});

//exports page with props and functions
export default connect(mapStateToProps, {getProject})(Project);