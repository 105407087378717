// File: src/reducers/rootReducer.js
// Author: Harley McDonald
// Version 1.0
// The root reducer is where we combin all our reducers together.

// import combineReducers
import { combineReducers } from '@reduxjs/toolkit';
// import our reducers
import experienceReducer from './experienceReducer';
import authReducer from './authReducer';
import alertReducer from './alertReducer';
import userReducer from './userReducer';
import projectReducer from './projectReducer';
import interestReducer from './interestReducer';

// combines the reducers into one root reducer
export default combineReducers({
  experience: experienceReducer,
  auth: authReducer,
  alert: alertReducer,
  users: userReducer,
  project: projectReducer,
  interests: interestReducer
});

