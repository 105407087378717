// File: src\components\dynamics\ThemeContext.js
// Author: Harley McDonald
// Version 1.0
// This file holds our light and dark themes as well as how to change between them

//imports react's create context function
import { createContext } from "react";

// an object containing all relevant classes and data for out themes
export const themes = {
  dark: {
    body: "bg-dark",
    compA: "bg-secondary",
    compB: "bg-dark border border-secondary",
    compC: "bg-dark border border-dark",
    bar: "bg-secondary",
    title: "text-light fw-bold",
    text: "text-light",
    link: "text-light fw-underline fw-bold",
    btn: "btn btn-secondary border border-dark",
    table: "table-striped table-dark table-bordered"
  },
  light: {
    body: "bg-white",
    compA: "bg-light border border-secondary",
    compB: "bg-white border border-secondary",
    compC: "bg-light",
    bar: "bg-primary",
    title: "text-primary fw-bold",
    text: "text-dark",
    link: "text-succes fw-underline fw-bold",
    btn: "btn btn-light border border-secondary",
    table: "table-striped table-bordered"
  },
};

//sets a function to change our themes context
export const ThemeContext = createContext({
  theme: themes.dark,
  changeTheme: () => {},
})