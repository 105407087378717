// File: src\components\auth\Register.js
// Author: Harley McDonald
// Version 1.0
// This file holds our login page.

//importing react and important components
import React, { Fragment, useState, useContext} from 'react';
import {Link, useNavigate, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
// Bring in our register function
import { register } from '../../actions/authActions';

// defines our component
const Register = ({ register, isAuthenticated }) => {
  //grabs our theme context from the surface level component
  const theme =  useContext(ThemeContext);

  //sets our form data to the state
  //adds our form data to the state
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    password: '',
    passwordCompare: '',
    errors: {}
  });

  // Destructure the form state.
  const { firstName, lastName, email, company, password, passwordCompare, errors } = formData;

  // Create our navigate var.
  const navigate = useNavigate();

  // On Change
  const onChange = e => setFormData(
    { ...formData, [e.target.name]: e.target.value }
  );

  // onSumbit function
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log('On sumbit - Register page');

    // Error checking
    var errorChk = false;
    var err = {};

    // Check errors first Name
    if (firstName === ''){
      err.firstName= 'First Name is required ';
      errorChk = true;
    }

    // Check errors last Name
    if (lastName === ''){
      err.lastName= 'Last Name is required ';
      errorChk = true;
    }

    // check for errors in the email field
    if (email === ''){
      err.email= 'Email is required ';
      errorChk = true;
    }
    
    // check for errors in the email field
    if (company === ''){
      err.company= 'Company is required ';
      errorChk = true;
    }

    // Check for errors in the password field
    if (password === ''){
      err.password= 'Password is required ';
      errorChk = true;
    }

    // check if the passwords match
    if(password !== passwordCompare)
    {
      err.passwordCompare= 'Passwords must match';
      errorChk = true; 
    }

    // check for errors in the passwordCompare field
    if (passwordCompare === ''){
      err.passwordCompare= 'Re-Enter Password is required ';
      errorChk = true;
    }

    //returns all errors
    if(errorChk){
      setFormData({...formData, errors});
      return;
    }

    // Create the user object
    const user = { firstName, lastName, email, company, password};
    // Call the register function
    await register(user);
    // redirect the browser
    navigate('/');
  }

  // Check to see if there is a user already logged in.
  // If there is, redirect to the home page. Otherwise the registration form is loaded
  return isAuthenticated ? (
    <Navigate to='/'/>
  ) : (
    <Fragment>
      <div className='container'>
      <h1 className={`${theme.theme.title} text-center p-3`}>Register for the System</h1>
      <div className={`card mb-3 ms-2 me-2 ${theme.theme.compA}`}>
        <div className={`card-header ${theme.theme.title} ${theme.theme.compA}  ${theme.theme.text}`}>
          Register (Currently disabled due to server issues)
        </div>
        <div className={`card-body ${theme.theme.compC} `}>
          <form onSubmit={e => onSubmit(e)}>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='firstName'>First Name</label>
              <input 
                type='text'
                className={`form-control ${errors.firstName ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='firstName'
                placeholder='First Name'
                name='firstName'
                value={firstName} 
                disabled= "disabled"
                onChange={e => onChange(e)}
              />
              {errors.firstName && <div className='invalid-experience'>{errors.firstName}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='lastName'>Last Name</label>
              <input 
                type='text'
                className={`form-control ${errors.lastName ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='lastName'
                placeholder='Last Name'
                name='lastName'
                value={lastName} 
                disabled= "disabled"
                onChange={e => onChange(e)}
              />
              {errors.lastName && <div className='invalid-experience'>{errors.lastName}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='email'>Email</label>
              <input 
                type="text"
                className={`form-control ${errors.email ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='email'
                placeholder='email@somewhere.com'
                name='email'
                value={email} 
                disabled= "disabled"
                onChange={e => onChange(e)}
              />
              {errors.email && <div className='invalid-experience'>{errors.email}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='company'>Company</label>
              <input
                className={`form-control ${errors.company ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='company'
                placeholder='company URL'
                name='company'
                value={company}
                disabled= "disabled"
                onChange={e => onChange(e)}
              />
              {errors.company && <div className='invalid-experience'>{errors.company}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='password'>Password</label>
              <input 
                type="password"
                className={`form-control ${errors.password ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='password'
                placeholder='password'
                name='password'
                value={password}
                disabled= "disabled"
                onChange={e => onChange(e)}
              />
              {/* Error message rendering */}
              {errors.password && <div className='invalid-experience'>{errors.password}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='passwordCompare'>Re-enter Password</label>
              <input 
                type="password"
                className={`form-control ${errors.passwordCompare ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='passwordCompare'
                placeholder='passwordCompare'
                name='passwordCompare'
                value={passwordCompare}
                disabled= "disabled"
                onChange={e => onChange(e)}
              />
              {/* Error message rendering */}
              {errors.passwordCompare && <div className={`invalid-experience`}>{errors.passwordCompare}</div>}
            </div>
            <div className='d-grid gap-2'>
              <input type='submit' value='Register' disabled= "disabled" className={`${theme.theme.btn}`}/>
            </div>
            <p className={`${theme.theme.text} m-1 col-6`}>
              Already have an account? <Link className={`${theme.theme.link} `} to='/users/login'>Sign In</Link>
            </p>
            <p className={`${theme.theme.text} m-1 col-6`}>I collect some private information, click <Link className={`${theme.theme.link} `} to='/privacy'>here</Link> to learn how that information is handled.</p>
          </form>
        </div>
      </div>
      </div>
    </Fragment>
  )
}

// set up proptypes
Register.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
}
// Map state to props.
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

// set up connect
export default connect(mapStateToProps, { register })(Register);