// File: client\src\store.js
// Author: Harley McDonald
// Version 1.0
// This file allows us to create a store to hold state for our application.

// Bring in configureStore, from the @reduxjs/toolkit.
import { configureStore } from '@reduxjs/toolkit';
// import our root reducer.
import rootReducer from './reducers/rootReducer';
//import storage
import storage from 'redux-persist/lib/storage';
//import persistant state functions
import {persistReducer, persistStore} from 'redux-persist';
import thunk from 'redux-thunk';

//sets persist's options
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ["alert"]
}

//initializes our persistant reducers
const persistedReducer = persistReducer(persistConfig, rootReducer)

// We need to configure our state to hold our state.
// The configureStore function takes 5 arguments.
// A reducer, middleware,devTools, preloadedState and enhancers.
export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  // devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
  // enhancers.
});

//exports our store
export const persistor = persistStore(store);