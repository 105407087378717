// File: /src/actions/alertActions.js
// Author: Harley McDonald
// Version 1.0
// This file holds our alert actions.
// This will allow us to send messages to the UI using the bootstrap alert component.

// Use uuid to create ids for our alerts.
import { v4 as uuidv4 } from 'uuid';
import { SET_ALERT, REMOVE_ALERT, CLEAR_ALERT } from './types';

// Create the setAlert function.
// This will take 3 parameters
// The message, the alertType, timeout with default value (used for removing the alert)
export const setAlert = ( msg, alertType, timeout = 10000) => dispatch => {
  // create the id for each alert.
  // This lets the UI know which alreat we need to remove later on.
  const id = uuidv4();
  // dispatch the alert and payload
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, id}
  });

  // Use JavaScript's setTimeout function to automatically remove the alert from the UI.
  setTimeout(() => dispatch ({ type: REMOVE_ALERT, payload: id}), timeout);
};

// logout function
export const removeAlerts = () => dispatch =>  {

  
  // dispatch to remove all alerts
  dispatch({
    type: CLEAR_ALERT
  });
};