// File: src/actions/authActions.js
// Author: Harley McDonald
// Version 1.0
// This file will hold all the actions for our auth.

// import Axios
import axios from 'axios';
// import action types.

import { 
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  GET_SELF_ID,
  USER_LOADED,
  ADMIN_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT
} from './types';

import setAuthToken from '../utils/setAuthToken';
// import setAlert
import { setAlert } from './alertActions';

// load user.
// this function will load a user into state.
export const loadUser = () => async dispatch => {
  // if there is a token in local storage, add it to our request headers.
  if(localStorage.token){
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get('/api/auth');
    // dispatch that the user is load.
    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error
    })
  }
}

// load admin.
// this function will load a bool for detemining if admin content should be shown into state.
export const loadAdmin = () => async dispatch => {

  try {
    const res = await axios.get('/api/auth/admin');

    // dispatch that the admin bool is load.
    dispatch({
      type: ADMIN_LOADED,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error
    })
  }
}

// Register a user.
export const register = (user) => async dispatch => {
  try {
    const res = await axios.post('/api/users/new', user);
    // dispatch a successful registration
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });

    // dispatch loaduser()
    dispatch(loadUser());
    dispatch(getSelfId());
    // We can send a message if the user has registered successfully.
    dispatch(setAlert('You have successfully Registered', 'success'));

  } catch (error) {
    // If we have errors we can show these in the UI
    // These messages are sent by the server.
    const errors = error.response.data.errors;
    if(errors){
      errors.forEach(err => dispatch(setAlert(err.msg, 'danger')));
    }

    // dispatch that we had an error while registering.
    dispatch({
      type: REGISTER_FAIL
    });
  }
}

// login function
export const login = (details) => async dispatch => {
  // try catch
  try {
    const res = await axios.post('/api/auth', details);
    // if we have no errors with the login, send the login success.
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });
    // We need to run the load user function when someone logins
    // dispatch loaduser()
    dispatch(loadUser());
    dispatch(loadAdmin());
    dispatch(getSelfId());
    // Dispatch message for successful login
    dispatch(setAlert('You have successfully logged in', 'success'));
  } catch (error) {
    // Dispatch error messages from server for login errors
    const errors = error.response.data.errors;
    if(errors){
      errors.forEach(err => dispatch(setAlert(err.msg, 'danger')));
    }
    // Dispatch login fail if there are errors
    dispatch({
      type: LOGIN_FAIL
    })
  }
}

// logout function
export const logout = () => dispatch => {
  // Dispatch a logout message
  dispatch(setAlert('You have logged out', 'warning'));
  //dispatches logout, removing user data from state
  dispatch({
    type: LOGOUT
  });
};

//function to set user's id for displaying experience edit and delete options
export const getSelfId = () => async dispatch => {
  //get's the user's id using their token
    try{
    const res = await axios.get("/api/auth/self");

    //sets the user's id for use in Experience
    dispatch({
      type: GET_SELF_ID,
      payload: res.data
    });
  }
  catch(error){
    dispatch({
      type: GET_SELF_ID,
      payload: undefined
    });
  }
};