// File: src\components\experience\Experience.js
// Author: Harley McDonald
// Version 1.0
// This file holds our the component for the experience page.

//importing react and important components
import React, { useEffect, useState, useContext} from 'react';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
// Import connect from react-redux.
import { connect } from 'react-redux';
// import our singleExperience component.
import SingleExperience from './SingleExperience';
// import our getExperience and add experience functions from the actions file
import { getExperience, addExperience } from '../../actions/experienceActions';
// imports our get all users function for linking authors to experience
import { getAll } from '../../actions/userActions';
//imports a component for creating modals
import ReactModal from 'react-modal';
//imports a component for framing modal content
import ModalFrame from '../layout/ModalFrame';
//imports used icons
import { FaPlus } from 'react-icons/fa';

// cour experience page component
const Experience = ({ getExperience, getAll, addExperience, loadingF, loadingU, all_users, experienceList, selfId, isAuthenticated }) => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);

if(selfId === undefined || selfId === null){
  selfId = {id:0};
}

  
  // Loads experience and users
  useEffect(() => {
    getExperience();
    getAll();
  }, [getExperience, getAll]);

  //Our toggle for displaying our modal form
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  //adds our form data to the state
  const [formData, setFormData] = useState({
    type: '',
    body: '',
    time: '',
    errors: {}
  });

  // Destructure the state.
  const { type, body, time, errors } = formData;

  // On Change
  const onChange = e => setFormData(
    { ...formData, [e.target.name]: e.target.value }
  );

  // onSumbit function
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log('On sumbit - ManageExperience page');

    // Error checking
    var errorChk = false;
    var err = {};
    
    // Check errors type
    if (type === ''){
      err.type = 'Experience Type is required ';
      errorChk = true;
    }
    
    // Check errors name
    if (body === ''){
      err.body = 'Name is required ';
      errorChk = true;
    }

    // Check errors time
    if (time === ''){
      err.time = 'Time spent working with is required';
      errorChk = true;
    }

    //returns all errors
    if(errorChk){
      setFormData({...formData, errors});
      return;
    }

    // Create the experience object
    const expPost = { type, body, time };
    console.log(expPost);
    // Call the register function
    await addExperience(expPost);
    //closes the modal
    setModalOpen(false);
  }

  //displays loading while loading and our page when not
  return (loadingF || loadingU) ? (
    <h2 className={`${theme.theme.title} text-center p-3`}> Loading... </h2>
  ) : (
    <div className='mt-3 container'>
      <h2 className={`${theme.theme.title} text-center p-3`}>Experience</h2>
      <div className={`${theme.theme.compA} rounded`}>
      { isAuthenticated ? <> : <FaPlus className='text-success mt-3 mx-3' onClick={toggleModal} size={50} title="Add Experience"/>
      <ReactModal isOpen={modalOpen} onRequestClose={toggleModal} className={`${theme.theme.body} position-absolute top-50 start-50 translate-middle w-50`}>
        <ModalFrame close={toggleModal}>
          <div>
          <form onSubmit={e => onSubmit(e)}>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='type'>Experience Type</label>
              <input 
                type='text'
                className={`form-control ${errors.type ? "is-invalid" : 'is-valid'} ${theme.theme.compA}`}
                id='type'
                placeholder='Please type out the type of thing you have experience with.'
                name='type'
                value={type} 
                onChange={e => onChange(e)}
              />
              {errors.type && <div className='invalid-experience'>{errors.type}</div>}
            </div>

            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='body'>Name</label>
              <input 
                type='text'
                className={`form-control ${errors.body ? "is-invalid" : 'is-valid'} ${theme.theme.compA}`}
                id='body'
                placeholder='Please type out the name of what you have experience with.'
                name='body'
                value={body} 
                onChange={e => onChange(e)}
              />
              {errors.body && <div className='invalid-experience'>{errors.body}</div>}
            </div>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='time'>Time spent working with</label>
              <input 
                type='text'
                className={`form-control ${errors.time ? "is-invalid" : 'is-valid'} ${theme.theme.compA}`}
                id='time'
                placeholder="Please type out how long you've practiced this."
                name='time'
                value={time} 
                onChange={e => onChange(e)}
              />
              {errors.time && <div className='invalid-experience'>{errors.time}</div>}
            </div>
            <div className='d-grid gap-2'>
              <input type='submit' value='Submit' className={`${theme.theme.btn}`}/>
            </div>
          </form>
          </div>
        </ModalFrame>
          <SingleExperience key={0} experience={{expId:0, type, body, time}} user={[{firstName: "Your", lastName: "Name"}]}></SingleExperience>
      </ReactModal></> : <div></div>}
      <div className={"pb-1 me-4"}><div style={{minHeight: "20px"}}></div>{console.log(experienceList)}
      {//loops through our top level experience
        
        experienceList.map(experience => { 
          //creates an object with the author information.
            let user = all_users.filter(usr => usr.userId === experience.authorId);
            //returns a experience component to display the experience, in a div container for the experience and it's replys
            if (experience.authorId === selfId.id){
              return <div className=''><SingleExperience key={experience.expId} experience={experience} selfId={selfId} user={user[0]}/></div>
            }
            else if(experience.authorId === 1){
              return <div className=''><SingleExperience key={experience.expId} experience={experience} selfId={selfId} user={user[0]}/></div>
            }
        })
      }
      </div>
      </div>
    </div>
  )
}

// Define the props that our component will get.
Experience.propTypes = {
  // ptor ptar ptfr 
  getExperience: PropTypes.func.isRequired,
  getAll: PropTypes.func.isRequired,
  addExperience: PropTypes.func.isRequired,
  experienceList: PropTypes.array.isRequired,
  all_users: PropTypes.array.isRequired,
  selfId: PropTypes.number.isRequired
};

// Define mapStateToProps.
const mapStateToProps = state => ({
  experienceList: state.experience.experienceList,
  loadingF: state.experience.loading,
  loadingU: state.users.loading,
  all_users: state.users.all_users,
  selfId: state.auth.selfId,
  isAuthenticated: state.auth.isAuthenticated,
});

//exports our component with all necessary props
export default connect(mapStateToProps, {getExperience, getAll, addExperience})(Experience);