// File: client\src\components\manangement\users\ManageUsers.js
// Author: Harley McDonald
// Version 1.0
// This file holds our user management page.

//importing react and important components
import React, { useEffect, useContext} from 'react';
import { connect } from 'react-redux';
//imports our theme context
import { ThemeContext } from '../../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
//imports the get users function
import { getUsers } from '../../../actions/userActions';
//imports the navigate component
import { Navigate } from 'react-router-dom';
//imports our single user component
import SingleUser from './SingleUser';

//our component
const ManageUsers = ({ loading, users, getUsers, isAdmin }) => {

  //grabs our theme context from the surface level component
  const theme =  useContext(ThemeContext);

  // Get all users
  useEffect(() => {
    getUsers();
  }, [getUsers]);
  
  //checks if the user is an admin and redirects them if not
  if(isAdmin !== true){
    return (<Navigate to='/'/>)
  }
  return loading ? (
    <h2 className={`${theme.theme.title} text-center p-3`}>Loading...</h2>
  ) : (
    <div className='m-3'>
      <div className={`card card-body mb-3 table-responsive ${theme.theme.compA}`}>
        <h2 className={`${theme.theme.title} text-center p-3`}>User List</h2>
          <table className={`table ${theme.theme.table} table-hover`}>
            <thead>
             <tr>
                <th className={`${theme.theme.text} `}  scope="col">ID</th>
                <th className={`${theme.theme.text} `}  scope="col">First Name</th>
                <th className={`${theme.theme.text} `}  scope="col">Last Name</th>
                <th className={`${theme.theme.text} `}  scope="col">Email</th>
                <th className={`${theme.theme.text} `}  scope="col">Admin</th>
                <th className={`${theme.theme.text} `}  scope="col">Image</th>
                <th className={`${theme.theme.text} `}  scope="col">Edit</th>
                <th className={`${theme.theme.text} `}  scope="col">Delete</th>
            </tr>
          </thead>
          <tbody className='table-group-divider'>
            {//loops through the users and displays them 
            users.filter((e)=>e.userId >= 1).map(user => (
            <SingleUser key={user.userId} user={user}/>
          ))}
          </tbody>
        </table>
    </div>
  </div>
  )
}

//checks prop types
ManageUsers.propTypes = {
  users: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getUsers: PropTypes.func.isRequired
}

//maps props to the state
const mapStateToProps = state => ({
  users: state.users.users,
  loading: state.users.loading,
  isAdmin: state.auth.isAdmin
});

//exports our page
export default connect(mapStateToProps, { getUsers })(ManageUsers);