// File: src/actions/experienceActions.js
// Author: Harley McDonald
// Version 1.0
// This file holds all our actions for our experience.

// Import our types.
import {
  GET_EXPERIENCE,
  GET_SINGLE_EXPERIENCE,
  ADD_EXPERIENCE,
  UPDATE_EXPERIENCE,
  DELETE_EXPERIENCE,
  DEL_ERROR
} from './types';
// import axios.
import axios from 'axios';

// Create our experience action functions.
// getExperience function. Get all experience

export const getExperience = () => async dispatch => {
  // try to call the api. Get all our experience from our endpoint.
  try {
    const res = await axios.get('/api/experience');
    // Dispatch our action and payload to the reducer.
    dispatch({
      type: GET_EXPERIENCE,
      payload: res.data
    });
  }
  catch(error){
    dispatch({
      type: GET_EXPERIENCE,
      payload: [
        //Template {expId: 0, type: "", body: "", time: "", authorId: 1},
        {expId: 1, type: "Programming Language", body: "Lua", time: "3 Months", authorId: 1},
        {expId: 2, type: "Programming Language", body: "Java", time: "6 Months", authorId: 1},
        {expId: 3, type: "Programming Language", body: "C#", time: "2 Years", authorId: 1},
        {expId: 4, type: "Programming Language", body: "Javascript", time: "2 Years", authorId: 1},
        {expId: 5, type: "Programming Language", body: "Python", time: "1 Year", authorId: 1},
        {expId: 6, type: "Programming Language", body: "PHP", time: "6 Months", authorId: 1},
        {expId: 7, type: "Programming Language", body: "Dart", time: "3 Months", authorId: 1},
        {expId: 8, type: "Programming Language", body: "Perl", time: "3 Months", authorId: 1},
        {expId: 9, type: "Markup Language", body: "HTML", time: "2.5 Years", authorId: 1},
        {expId: 10, type: "Markup Language", body: "XML", time: "1 Year", authorId: 1},
        {expId: 11, type: "Markup Language", body: "HXML", time: "6 Months", authorId: 1},
        {expId: 12, type: "Stylesheet Language", body: "CSS", time: "1 Year", authorId: 1},
        {expId: 13, type: "Framework", body: "Flutter", time: "3 Months", authorId: 1},
        {expId: 14, type: "Framework", body: "React", time: "6 Months", authorId: 1},
        {expId: 15, type: "Framework", body: "ASP.NET", time: "1 Year", authorId: 1},
        {expId: 16, type: "Framework", body: "jQuery", time: "1 Year", authorId: 1},
        {expId: 17, type: "Framework", body: "Vue", time: "6 Months", authorId: 1},
        {expId: 18, type: "Framework", body: "Bootstrap", time: "1.5 Years", authorId: 1},
        {expId: 19, type: "Templating Language", body: "Ejs", time: "6 Months", authorId: 1},
        {expId: 20, type: "Database", body: "MySQL", time: "6 Months", authorId: 1},
        {expId: 21, type: "Database", body: "PostgreSQL", time: "6 Months", authorId: 1},
        {expId: 22, type: "Database", body: "Microsoft SQL Server", time: "1.5 Years", authorId: 1},
        {expId: 23, type: "Database", body: "MongoDB", time: "6 Months", authorId: 1},
        {expId: 24, type: "Database", body: "SQLite", time: "1 Year", authorId: 1}
      ]
    });
  }
};

//Gets a single experience entry by id, deprecated
export const getSingleExperience = (id) => async dispatch => {
  // try to call the api for a single experience
  try {
    const res = await axios.get(`/api/experience/edit/${id}`);
    // dispatch action and payload.
    dispatch({
      type: GET_SINGLE_EXPERIENCE,
      payload: res.data
    });
  }
  catch(error){
    dispatch({
      type: GET_SINGLE_EXPERIENCE,
      payload: {}
    });
  }
};

//adds a new experience to db
export const addExperience = (experience) => async dispatch => {
  console.log(experience)
  // call the api
  const res = await axios.post('/api/experience/add', experience);
  // dispatch action and payload.
  dispatch({
    type: ADD_EXPERIENCE,
    payload: res.data
  });
};

//updates an existing experience, requires the user be the experience's author
export const updateExperience = (experience) => async dispatch => {
  // call the api to update experience
  const res = await axios.put(`/api/experience/edit/${experience.expId}`, experience);
  // dispatch action and payload.
  dispatch({
    type: UPDATE_EXPERIENCE,
    payload: res.data
  })
};

//deletes a experience from the data base.
export const deleteExperience = (id) => async dispatch => {
  // call api to delete experience
  try {
    await axios.delete(`/api/experience/edit/${id}`);
    // Dispatch
    dispatch({
      type: DELETE_EXPERIENCE,
      payload: id
    })
  } catch (error) {
    dispatch({
      type: DEL_ERROR,
      payload: error
    })
  }

  // dispatch
}

