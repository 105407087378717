// File: /src/reducers/userReducer.js
// Author: Harley McDonald
// Version 1.0
// This file holds all our state and reducer that will deal with our users.

// import our types
import {
  GET_USERS,
  GET_USER,
  GET_SELF,
  GET_ALL
} from '../actions/types';

// create the inital State
const initialState = {
  users: [],
  all_users: [],
  user: {},
  loading: true,
  erorrs: {}
};

// Create our userReducer to change our state.
export default function userReducer(state = initialState, action){
  const { type, payload} = action;
  switch(type){
    case GET_USERS:
      return {
        ...state,
        users: payload,
        loading: false
      }
    case GET_USER:
      return {
        ...state,
        user: payload,
        loading: false
      }
    case GET_ALL:
      return {
        ...state,
        all_users: payload || [],
        loading: false
      }
    case GET_SELF:
      return {
        ...state,
        self: payload,
        loading: false
      }
    default:
      return state;
  }
}