// src/actions/types.js
// Author: Harley McDonald
// Version 1.0
// This file contains all the action types we can dispatch and use in our application.
// List our experience action types.
export const GET_EXPERIENCE = 'GET_EXPERIENCE';
export const GET_SINGLE_EXPERIENCE = 'GET_SINGLE_EXPERIENCE';
export const ADD_EXPERIENCE = 'ADD_EXPERIENCE';
export const UPDATE_EXPERIENCE = 'UPDATE_EXPERIENCE';
export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';
export const DEL_ERROR = 'DEL_ERROR';
// Auth Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_SELF_ID = 'GET_SELF_ID';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const ADMIN_LOADED = 'ADMIN_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
// Alert Types
// These are for messages sent to the UI
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
// User Types
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const GET_SELF = 'GET_SELF';
export const UPDATE_SELF = 'UPDATE_SELF';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_SELF = 'DELETE_SELF';
export const GET_ALL = 'GET_ALL';
//Project Types
export const GET_PROJECT = 'GET_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
//Interest Types
export const GET_INTERESTS = 'GET_INTERESTS';
export const GET_INTEREST = 'GET_INTEREST';
export const ADD_INTEREST = 'ADD_INTEREST';
export const UPDATE_INTEREST = 'UPDATE_INTEREST';
export const DELETE_INTEREST = 'DELETE_INTEREST';