// File: client\src\components\manangement\EditInterests.js
// Author: Harley McDonald
// Version 1.0
// This file holds our interest management page.

//importing react and important components
import React, {useState, useEffect, useContext} from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
//imports our interest functions
import { getInterests, addInterest } from '../../actions/interestActions';
//imports icons
import { FaPlus } from 'react-icons/fa';
//imports a component for creating modals
import ReactModal from 'react-modal';
//imports a component for framing modal content
import ModalFrame from '../layout/ModalFrame';
import SingleEditInterest from './SingleEditInterest';

//our component
const EditInterests = ({isAdmin, loading, getInterests, addInterest, interests}) => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);
  var i = 0;

  //sets an array for categories, generated from existing interests categories
  const [categories, setCategories] = useState([...new Set( interests.map(obj => obj.category)) ])

  //initialises our interests
  useEffect(()=>{
    getInterests();
  },[getInterests])

  //Our toggle for displaying our modal form
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  //adds our form data to the state
  const [formData, setFormData] = useState({
    name: "",
    category: "",
    newCategory: "",
    about: "",
    errors: {}
  });

  // Destructure the state.
  const { name, category, newCategory, about, errors } = formData;

  // On Change
  const onChange = e => { console.log(e.target.name); setFormData({ ...formData, [e.target.name]: e.target.value })};

  // onSumbit function
  const onSubmit = async (e) => {
    e.preventDefault();

    console.log('On sumbit - ManageProject page');

    var finalCat = "";

    // Error checking
    var errorChk = false;
    var err = {};

    // Check errors interest Name
    if (name === ''){
      err.name = 'Interest Name is required';
      errorChk = true;
    }
    // Check errors for category
    if (category === '' && newCategory === ''){
      console.log(category)
      err.category = 'Category is required';
      errorChk = true;
    }
    // Check errors about
    if (about === ''){
      err.about = 'About is required';
      errorChk = true;
    }

    //returns all errors
    if(errorChk){
      setFormData({...formData, errors: err});
      return;
    }
    
    else{//else set the final category, prioritising the selected
      if(newCategory === ''){
        finalCat = category;
      }
      else{
        finalCat = newCategory;
        setCategories({...categories, newCategory});
      }
    }

    // Create the interest object
    const interest = { name, category: finalCat, about};
    
    // Call the register function
    await addInterest(interest);

    //closes the modal
    setModalOpen(false);
  }
  
  //checks if the user is an admin and redirects them if not
  if(isAdmin !== true){
    return (<Navigate to='/'/>)
  }

  //displays loading while loading and our page when not
  return loading ? (
    <h2 className={`${theme.theme.title} text-center p-3`}>Loading...</h2>
  ) : (
    <div className='m-3'>
        <h2 className={`${theme.theme.title} text-center p-3`}>Interest List</h2><div>
      <FaPlus className='text-success p-1 ms-3' onClick={toggleModal} style={{cursor: 'pointer' }} title="Add Interest"  size={60}/>
      <ReactModal isOpen={modalOpen} onRequestClose={toggleModal} className={`${theme.theme.body} position-absolute top-50 start-50 translate-middle w-50`}>
        <ModalFrame close={toggleModal}>
          <div>
          <form onSubmit={e => onSubmit(e)}>
            <div className={` mb-3`}>
              <label className={`${theme.theme.text} `}  htmlFor='name'>Interest Name</label>
              <input 
                type='text'
                className={`form-control ${errors.name ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='name'
                placeholder='Interest Name'
                name='name'
                onChange={e => onChange(e)}
              />
              {errors.name && <div className='invalid-experience'>{errors.name}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='category'>Category</label>
              <select id='category' name= "category" onChange={e => onChange(e)}>
                <option value="">Please Select a Category</option>
                {categories.map(cat => (<option value={cat}>{cat}</option>))}
              </select>
              {errors.category && <div className='invalid-experience'>{errors.category}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='newCategory'>New Category</label>
              <input
                className={`form-control ${errors.newCategory ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='newCategory'
                placeholder='New Category'
                name='newCategory'
                onChange={e => onChange(e)}
              />
              {errors.newCategory && <div className='invalid-experience'>{errors.newCategory}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='about'>About</label>
              <textarea
                className={`form-control ${errors.about ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='about'
                placeholder='About'
                name='about'
                onChange={e => onChange(e)}
              />
              {errors.about && <div className='invalid-experience'>{errors.about}</div>}
            </div>
            <div className='d-grid gap-2'>
              <input type='submit' value='Submit' className={`${theme.theme.btn}`}/>
            </div>
          </form>
          </div>
        </ModalFrame>
      </ReactModal>
      </div>
      <div className='m-4'>
          <table className={`table ${theme.theme.table} table-hover`}>
            <thead >
              <tr className='position-relative'>
                <th className={`${theme.theme.title} text-center align-middle col-4`} >Name</th>
                <th className={`${theme.theme.title} text-center align-middle col-6`} >About</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody className='table-group-divider'>
            {interests.map(interest => {
              //checks if a new category needs to be displayed and then moves along the array
              if(interest.category === categories[i]){
              i++;
              //adds both category row and triggering interest
              return <><tr><td className={`${theme.theme.title} text-center align-middle col-12`} colSpan="4">{categories[(i-1)]}</td></tr><SingleEditInterest interest={interest} categories={categories}/> </>
            } //otherwise just displays interest
              return  <SingleEditInterest interest={interest} categories={categories}/> 
            })}
            </tbody>
          </table>
      </div>
    </div>
  )
}

//checks prop types
EditInterests.propTypes = {
  interests: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getInterests: PropTypes.func.isRequired,
  addInterest: PropTypes.func.isRequired
}

//sets maps props to state
const mapStateToProps = state => ({
  interests: state.interests.interests,
  loading: state.interests.loading,
  isAdmin: state.auth.isAdmin
});

//exports our page
export default connect(mapStateToProps, {getInterests,  addInterest})(EditInterests);