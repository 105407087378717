// File: client\src\index.js
// Author: Harley McDonald
// Version 1.0
// This file hols the very top level of our app.

//importing react and important components
import React from 'react';
import ReactDOM from 'react-dom/client';
//imports our index's css
import './index.css';
// Import Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//import our app
import App from './App';
//imports web vitals
import reportWebVitals from './reportWebVitals';
//imports our store and persister functions
import { persistor, store } from './store';
import {PersistGate} from 'redux-persist/integration/react'
// import our provider from react-redux.
import { Provider } from 'react-redux';
//imports our theme context provider
import ThemeContextWrapper from './components/dynamics/ThemeContextWrapper';

//captures the pages root and renders the app in it
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeContextWrapper>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
  </ThemeContextWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
