// File: client\src\components\layout\header\Dropdown.js
// Author: Harley McDonald
// Version 1.0
// This file holds our sub menuess.

// Bring in Link for our react-router-dom
import { Link } from 'react-router-dom';
//import icon list for navigation options
import NavIcon from './NavIcon';

//our component
const Dropdown = ({ submenus, dropdown, isAuthenticated, isAdmin }) => {
  return (
    <ul className={`dropdown${dropdown ? "show" : ""}`}>
      {submenus.map((submenu, index) => (
        //checks if user should see options
        (submenu.login === isAuthenticated && (submenu.admin === isAdmin || submenu.admin === undefined)) || (submenu.base) ? (<li key={index} className="menu-items">
          <Link className="nav-link active" aria-current="page" to={`${submenu.url}`}><NavIcon title={submenu.title}/> {submenu.title}</Link>
        </li>):(<></>)
      ))}
    </ul>
  );
};

//exports our component
export default Dropdown;