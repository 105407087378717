// File: /src/reducers/interestReducer.js
// Author: Harley McDonald
// Version 1.0
// This file holds all our state and reducer that will deal with our interests.

// import our types
import {
  GET_INTERESTS,
  GET_INTEREST,
  GET_SELF,
  ADD_INTEREST,
  UPDATE_INTEREST,
  DELETE_INTEREST
} from '../actions/types';

// create the inital State
const initialState = {
  interests: [],
  all_interests: [],
  interest: {},
  loading: true,
  erorrs: {}
};

// Create our interestReducer to change our state.
export default function interestReducer(state = initialState, action){
  const { type, payload} = action;
  switch(type){
    case GET_INTERESTS:
      return {
        ...state,
        interests: payload || [],
        loading: false
      }
    case GET_INTEREST:
      return {
        ...state,
        interest: payload,
        loading: false
      }
    case ADD_INTEREST:
      return {
        ...state,
        interest: payload,
        loading: true
      }
    case UPDATE_INTEREST:
      return {
        ...state,
        interest: payload,
        loading: true
      }
    case DELETE_INTEREST:
      return {
        ...state,
        loading: true
      }
    case GET_SELF:
      return {
        ...state,
        self: payload,
        loading: false
      }
    default:
      return state;
  }
}