// File: src\components\experience\SingleExperience.js
// Author: Harley McDonald
// Version 1.0
// This file holds our the component for the experience display component.
//importing react and important components
import React, {useState, useContext} from 'react';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
// import connect
import { connect } from 'react-redux';
// import icons
import { FaTimes, FaPencilAlt, } from 'react-icons/fa';
// import Delete, Add and Update functions.
import { deleteExperience, updateExperience } from '../../actions/experienceActions';
//imports a component for creating modals
import ReactModal from 'react-modal';
//imports a component for framing modal content
import ModalFrame from '../layout/ModalFrame';

// Destructure props.
const SingleExperience = ({experience, deleteExperience, updateExperience, user, selfId }) => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);
  //checks if selfId is set and if not, defaults to 0 
  if (selfId === undefined){
    selfId = {id:0}
  }

  // Destucture experience object.
  const { expId, type, body, time } = experience;

  //Our toggle for displaying our Edit modal form
  const [modalOpenE, setModalOpenE] = useState(false);
  const toggleModalE = () => {
    setModalOpenE(!modalOpenE)
  }

  //adds our form data to the state
  const [formData, setFormData] = useState({
    fType: '',
    fBody: '',
    fTime: '', 
    errors: {}
  });

  // Destructure the state.
  const { fType, fBody, fTime, errors } = formData;

  // On Change
  const onChange = e => setFormData(
    { ...formData, [e.target.name]: e.target.value }
  );

  // onSumbit function
  const onSubmit =  (e, type) => {
    e.preventDefault();
    console.log('On sumbit - ManageProject page');

    // Error checking
    var errorChk = false;
    var err = {};
    
    // Check errors type
    if (fType === ''){
      err.fType = 'Experience Type is required ';
      errorChk = true;
    }
    
    // Check errors name
    if (fBody === ''){
      err.fBody = 'Name is required ';
      errorChk = true;
    }

    // Check errors time
    if (fTime === ''){
      err.fTime = 'Time spent working with is required';
      errorChk = true;
    }

    //returns all errors
    if(errorChk){
      setFormData({...formData, errors: err});
      return;
    }

    //call the edit function
    if(type === "Edit"){
      // Create the experience object
      const expPost = {expId ,type: fType, body: fBody, time: fTime};
      //sends request to server and closes Modal
      updateExperience(expPost);
      toggleModalE();
    }
  }

  //our component
  return (
    <div className={'ps-5'}>
            <table className={`table ${theme.theme.table} table-hover border border-success rounded`}>
              <tbody>
                <tr>
                  <td className={`${theme.theme.title} text-center align-middle col-4`} ><b>Type of Experience: </b></td>
                  <td className={`${theme.theme.title} text-center align-middle col-6`} >{type}</td>
                  {/* checks if user is experience author */ selfId.id === user.userId ? (<>
                  <td className={`${theme.theme.title} text-center align-middle col-2`} >Editing</td></>):(<></>)}
                </tr>
                <tr>
                  <td className={`${theme.theme.title} text-center align-middle col-4`} ><b>Name: </b></td>
                  <td className={`${theme.theme.title} text-center align-middle col-6`} >{body}</td>
                    {/* checks if user is experience author */ selfId.id === user.userId ? (<>
                  <td className={`${theme.theme.title} text-center align-middle col-2`} >
                  <FaTimes className='text-danger p-1' size={60}
                    onClick={ e => deleteExperience(expId)}
                    style={{ cursor: 'pointer'}} title='Delete'
                  /></td></>):(<></>)}
                </tr>
                <tr>
                  <td className={`${theme.theme.title} text-center align-middle col-4`} ><b>Time spent working with: </b></td>
                  <td className={`${theme.theme.title} text-center align-middle col-6`} >{time}</td>
                  {/* checks if user is experience author */ selfId.id === user.userId ? (<>
                  <td className={`${theme.theme.title} text-center align-middle col-2`} >
                    <FaPencilAlt className='text-primary p-1'
                      onClick={ e => {toggleModalE()}}
                      style={{cursor: 'pointer' }} title="Edit"  size={50}/>
                      <ReactModal isOpen={modalOpenE} onRequestClose={toggleModalE} className={`${theme.theme.body} position-absolute top-50 start-50 translate-middle w-50`}>
                      <ModalFrame close={toggleModalE}>
                        <div>
                          <h2>Edit</h2>
                        <form onSubmit={e => onSubmit(e, "Edit")}>
                          <div className='mb-3'>
                            <label className={`${theme.theme.text} `} htmlFor='fType'>Experience Type</label>
                            <input 
                              type='text'
                              className={`form-control ${errors.fType ? "is-invalid" : 'is-valid'} ${theme.theme.compA}`}
                              id='fType'
                              placeholder='Please type out the type of thing you have experience with.'
                              name='fType'
                              value={`${fType === '' ? type : fType}`} 
                              onChange={e => onChange(e)}
                            />
                            {errors.fType && <div className='invalid-experience'>{errors.fType}</div>}
                          </div>

                          <div className='mb-3'>
                            <label className={`${theme.theme.text} `} htmlFor='fBody'>Name</label>
                            <input 
                              type='text'
                              className={`form-control ${errors.fBody ? "is-invalid" : 'is-valid'} ${theme.theme.compA}`}
                              id='fBody'
                              placeholder='Please type out the name of what you have experience with.'
                              name='fBody'
                              value={`${fBody === '' ? body : fBody}`} 
                              onChange={e => onChange(e)}
                            />
                            {errors.fBody && <div className='invalid-experience'>{errors.fBody}</div>}
                          </div>
                          
                          <div className='mb-3'>
                            <label className={`${theme.theme.text} `} htmlFor='fTime'>Time spent working with</label>
                            <input 
                              type='text'
                              className={`form-control ${errors.fTime ? "is-invalid" : 'is-valid'} ${theme.theme.compA}`}
                              id='fTime'
                              placeholder="Please type out how long you've practiced this."
                              name='fTime'
                              value={`${fTime === '' ? time : fTime}`} 
                              onChange={e => onChange(e)}
                            />
                            {errors.fTime && <div className='invalid-experience'>{errors.fTime}</div>}
                          </div>

                          <div className='d-grid gap-2'>
                            <input type='submit' value='Submit'  className={`${theme.theme.btn}`}/>
                          </div>
                        </form>
                        </div>
                      </ModalFrame>
                        <SingleExperience key={0} experience={{expId:0, type: (fType === '' ? type : fType), body: (fBody === '' ? body : fBody), time: (fTime === '' ? time : fTime)}} selfId={selfId} user={{userId:``}}></SingleExperience>
                    </ReactModal></td></>):(<></>)}
                </tr>
              </tbody>
            </table>
      </div>
  );
}

// Prop type checking
SingleExperience.propTypes = {
  experience: PropTypes.object.isRequired,
  deleteExperience: PropTypes.func.isRequired,
  updateExperience: PropTypes.func.isRequired,
}

// Use connect.
// mapStateToProps is not needed as experience is from the parent component and the function is from our actions. We do not need to see anything in our state for this component.
export default connect(null, {deleteExperience, updateExperience})(SingleExperience)