// File: client\src\components\manangement\users\Profile.js
// Author: Harley McDonald
// Version 1.0
// This file holds our profile page.

//importing react and important components
import React, {useState, useContext, useEffect} from 'react';
import { connect } from 'react-redux';
//imports our theme context
import { ThemeContext } from '../../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
//imports navigation component and funtion
import {useNavigate, Navigate } from 'react-router-dom';
//imports self user functions
import { getSelf, updateSelf, deleteSelf } from '../../../actions/userActions';

//our component
const Profile = ({isAuthenticated, self, getSelf, updateSelf, deleteSelf}) => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);

  // Get all users
  useEffect(() => {
    getSelf();
  }, [getSelf]);
  
  //deconstructs the self state
  const {firstName, lastName, company} = self;

  //sets up a string state for selecting type of submit for one form
  const [formSubmit, setFormSubmit] = useState("");
  const formType = (type) => {
    setFormSubmit(type);
  }

  //creates our navigate variable
  const navigate = useNavigate();

  //adds our form data to the state
  const [formData, setFormData] = useState({
    _firstName: firstName,
    _lastName: lastName,
    _company: company,
    password: "",
    newPassword: "",
    comparePassword: "",
    errors: {}
  });

  // Destructure the state.
  const { _firstName, _lastName, _company, password, newPassword, comparePassword,  errors } = formData;

  // On Change
  const onChange = e => {
    e.target.name !== "_isAdmin" ? setFormData({ ...formData, [e.target.name]: e.target.value })
    : setFormData({ ...formData, [e.target.name]: !e.target.value })
  }
  

  // onSumbit function
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log('On sumbit - ManageProject page');
    // Error checking
    // Check errors first Name
    if (_firstName === ''){
      setFormData({ ...formData, errors: {_firstName: 'First Name is required '}});
      return; 
    }
    // Check errors last Name
    if (_lastName === ''){
      setFormData({ ...formData, errors: {_lastName: 'Last Name is required '}});
      return; 
    }
    // Check passwords match
    if (newPassword !== comparePassword){
      setFormData({ ...formData, errors: {comparePassword: 'New Passwords don\'t matchis required '}});
      return; 
    }
    // Create the user object
    const newU = {firstName: _firstName, lastName: _lastName, company:_company, password, newPassword};
    // Call the edit self function
    if(formSubmit === "Edit"){
      await updateSelf(newU);
    }
    //Call the delete self function and returns to home
    if(formSubmit === "Delete"){
      await deleteSelf(newU);
      navigate('/');
    }
  }
  
  //checks if the user is an admin and redirects them if not
  if(isAuthenticated !== true){
    return (<Navigate to='/'/>)
  }
  return (
    <div className='container'>
      <h1 className={`${theme.theme.title} text-center p-3`}>Edit Profile</h1>
    <div className={`card mb-3 ms-2 me-2 ${theme.theme.compA}`}>
      <div className={`card-header ${theme.theme.title} ${theme.theme.compA}  ${theme.theme.text}`}>
        Profile
      </div>
      <div className={`card-body ${theme.theme.compC} `}>
          <form onSubmit={e => onSubmit(e)}>
            <div className={` mb-3`}>
              <label className={`${theme.theme.text} `}  htmlFor='_firstName'>First Name</label>
              <input 
                type='text'
                className={`form-control ${errors.firstName ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='_firstName'
                placeholder='First Name'
                name='_firstName'
                value={_firstName} 
                onChange={e => onChange(e)}
              />
              {errors.firstName && <div className='invalid-experience'>{errors.firstName}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='_lastName'>Last Name</label>
              <input 
                type='text'
                className={`form-control ${errors.lastName ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='_lastName'
                placeholder='Last Name'
                name='_lastName'
                value={_lastName} 
                onChange={e => onChange(e)}
              />
              {errors.lastName && <div className='invalid-experience'>{errors.lastName}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='_company'>Company</label>
              <input
                className={`form-control ${errors.company ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='_company'
                placeholder='Company'
                name='_company'
                value={_company}
                onChange={e => onChange(e)}
              />
              {errors.company && <div className='invalid-experience'>{errors.company}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='password'>Old Password</label>
              <input 
                type="password"
                className={`form-control ${errors.password ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='password'
                placeholder='password'
                name='password'
                value={password}
                onChange={e => onChange(e)}
              />
              {/* Error message rendering */}
              {errors.password && <div className='invalid-experience'>{errors.password}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='newPassword'>New Password</label>
              <input 
                type="password"
                className={`form-control ${errors.newPassword ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='newPassword'
                placeholder='newPassword'
                name='newPassword'
                value={newPassword}
                onChange={e => onChange(e)}
              />
              {/* Error message rendering */}
              {errors.newPassword && <div className='invalid-experience'>{errors.newPassword}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='comparePassword'>Re-enter New Password</label>
              <input 
                type="password"
                className={`form-control ${errors.comparePassword ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='comparePassword'
                placeholder='Compare Password'
                name='comparePassword'
                value={comparePassword}
                onChange={e => onChange(e)}
              />
              {/* Error message rendering */}
              {errors.comparePassword && <div className={`invalid-experience`}>{errors.passwordCompare}</div>}
            </div>
            <div className='d-grid gap-2'>
              <input type='submit' value='Submit' className={`${theme.theme.btn}`}  onClick={e => formType("Edit") }/>
              <input type='submit' value='Delete Account' className={`${theme.theme.btn}`}  onClick={e => formType("Delete")}/>
            </div>
          </form>
          </div>
          </div>
      </div>
  )
}

//checks prop types
Profile.propTypes = {
  getSelf: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  updateSelf: PropTypes.func.isRequired,
  deleteSelf: PropTypes.func.isRequired,
  self: PropTypes.object.isRequired
}

//maps props to the state
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  self: state.users.self
});

//exports our page
export default connect(mapStateToProps, {getSelf, updateSelf, deleteSelf})(Profile);