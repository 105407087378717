// File: src/actions/projectActions.js
// Author: Harley McDonald
// Version 1.0
// This file holds all our actions for our project.

// Import our types.
import {
  GET_PROJECT,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  DEL_ERROR
} from './types';
// import axios.
import axios from 'axios';

// Create our project action functions.
// getProject function. Get all project posts
// This function will contain our api call, dispatch, action type and payload.
// It return all project from the database.
export const getProject = () => async dispatch => {
  // call the api. Get all our project from our endpoint.
  try{
    const res = await axios.get('/api/project');
    // Dispatch our action and payload to the reducer.
    dispatch({
      type: GET_PROJECT,
      payload: res.data
    });
  }
  catch(error){
    dispatch({
      type: GET_PROJECT,
      payload: [
        /*Template {projectId: 0, title: "", type: "",
        lang: "", time: "", satisf: "",
        desc: "",
        hiLit: "",
        selfRat: "",
        takeAway: ""},*/
        {projectId: 1, title: "Terraforming Mars Mod", type: "Personal",
        lang: "Lua", time: "2 Months", satisf: "High",
        desc: "Taking scripting provided by a wide range of amateur coders and add simple counters and automation.",
        hiLit: "Figuring how to code tags from cards so that certain cards could track certain other types of cards after being played.",
        selfRat: "2/5 Needed a lot of improvement but I made do my with resources at the time.",
        takeAway: "I needed to learn more skills from professionals."},
        {projectId: 2, title: "Mindeze", type: "Professional",
        lang: "Dart(Flutter), C#", time: "3 Months", satisf: "High",
        desc: "An App to help carers of early stage dementia patients set and track tasks to ensure that  patients are able to self manage for longer and give carers peace of mind.",
        hiLit: "Learning the Flutter framework and figuring out how to synchronise two apps over Firebase and an SSMS SQL server.",
        selfRat: "4/5 A solid project, my best work for the time but workload could have been better streamlined.",
        takeAway: "Working as part of a small team on a small project can feel very rewarding but in such an informal setting co-ordination can be difficult."},
        {projectId: 3, title: "Casey Tamil Manram Inventory Management System", type: "Educational",
        lang: "Javascript(ejs)", time: "1 year", satisf: "Low",
        desc: "A web application to manage the tracking of items used by the community organisation for festivals, which was stored by individual members on personal property.",
        hiLit: "Creating a system to separately track addresses used already in the database and allow members to automatically select them instead of recreating the address.",
        selfRat: "3/5 I did the best I could with the divided attention between projects, but the lack of team cohesion made progress hard.",
        takeAway: "A team with no common ground and zero clear leadership is a poor environment to work on a project."},
      ]
    });
  }
};

export const addProject = (project) => async dispatch => {
  // call the api to add a project
  const res = await axios.post('/api/project/add', project);
  // dispatch action and payload.
  dispatch({
    type: ADD_PROJECT,
    payload: res.data
  });
};

export const updateProject = (project) => async dispatch => {
  // call the api to update a project
  const res = await axios.put(`/api/project/edit/${project.projectId}`, project);
  // dispatch action and payload.
  dispatch({
    type: UPDATE_PROJECT,
    payload: res.data
  })
};

export const deleteProject = (id) => async dispatch => {
  // call api to delete a project
  try {
    await axios.delete(`/api/project/edit/${id}`);
    // Dispatch
    dispatch({
      type: DELETE_PROJECT,
      payload: id
    })
  } catch (error) {
    dispatch({
      type: DEL_ERROR,
      payload: error
    })
  }

}

