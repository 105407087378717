// File: client\src\components\pages\Home.js
// Author: Harley McDonald
// Version 1.0
// This file holds our home page.

//importing react and important components
import React, { useContext} from 'react';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports link component
import { Link } from 'react-router-dom'
//import image
import keyboard from '../../Assets/img/keyboard-70506_1920.jpg';

//our component
const Home = () => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);
  return (
    <div className='container'>
      <h2 className={`text-center ${theme.theme.title} p-3`}>Home</h2>
      <div className={`p-1 m-4 ${theme.theme.compB} rounded row`}>
        <div className='float-start'><img className='rounded float-end d-inline-block p-2' src={keyboard} alt='Keyboard' style={{minWidth: "250px", maxWidth: "40%"}}/>
          <h3 className={`text-start ${theme.theme.title} p-1 mt-2`}>Welcome</h3>
          <div className={`${theme.theme.text} text-start p-1`} >
            <p style={{textIndent: "20px"}}>Hi! Thank you for taking the time to look over my portfolio. On this site you will find a host of information about my continuing career as 
              a programmer and software developer, as well as a bit of information about what drives me as a person. 
            </p>
            {/*<p style={{textIndent: "20px"}}>In addition, if you take the time 
            to register, you will gain access to some of the interactive aspects of this site, notably the ability to add your own experiences, whether 
            that's programming or business! These won't show to other users so feel free to have fun with it.</p>*/}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className={`p-1 m-4 ${theme.theme.compA} rounded col-sm`}>
          <Link className={`text-start p-3 ${theme.theme.link}`} to='/info/about'>About Me</Link>
          <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>Find a bit about my journey into programming. Learn things like what inspired me to pursue this career and how I chose to follow that dream.</p>
        </div>

        <div className={`p-1 m-4 ${theme.theme.compA} rounded col-sm`}>
          <Link className={`text-start p-3 ${theme.theme.link}`} to='/info/experience'>Experience</Link>
          <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>Check here to see what languages, frameworks and other relevant subjects I have experience in and how long I've worked with them.</p>
        </div>

        <div className={`p-1 m-4 ${theme.theme.compA} rounded col-sm`}>
          <Link className={`text-start p-3 ${theme.theme.link}`} to='/info/project'>Projects</Link>
          <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>Learn about some of the projects I've worked on before and how I've grown and learned from their triumphs and mistakes.</p>
        </div>

        <div className={`p-1 m-4 ${theme.theme.compA} rounded col-sm`}>
          <Link className={`text-start p-3 ${theme.theme.link}`} to='/info/interests'>Interests</Link>
          <p className={`${theme.theme.text} p-1`} style={{textIndent: "20px"}}>Just a place to catalog what interests me as a person, both professionally and personally.</p>
        </div>
      </div>
    </div>
  )
}

//exports our page
export default Home