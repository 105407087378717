// File: client\src\components\information\SingleProject.js
// Author: Harley McDonald
// Version 1.0
// This file holds our single project component.

//importing react and important components
import React, {useContext} from 'react';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
// import connect
import { connect } from 'react-redux'; 

//our component
const SingleProject = ({project,i}) => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);
  //destructures the project state
  const {  title, type, lang, time, desc, hiLit, satisf, selfRat, takeAway } = project;

  return (
    <table className={`p-1 table ${theme.theme.table} table-hover rounded`} style={{overflow: "hidden"}}>
    <tbody>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Project Title: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{title}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Type: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{type}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Components: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{lang}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Duration: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{time}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Description: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{desc}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Highlight: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{hiLit}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Satisfaction Level: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{satisf}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Self Rating: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{selfRat}</td></tr>
    <tr><td className={`${theme.theme.title} text-center align-middle col-3`} ><b>Lesson Learned: </b></td>
    <td className={`${theme.theme.title} text-center align-middle col-9`} >{takeAway}</td></tr>
    </tbody>
    </table>
  )
}
// Prop type checking
SingleProject.propTypes = {
  project: PropTypes.object.isRequired
}

// exports our component
export default connect(null)(SingleProject);