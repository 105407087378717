// File: /src/components/layout/ModalFrame.js
// Author: Harley McDonald
// Version 1.0
//This file contains our modal frame

//importing react and important components
import React, {useContext} from 'react';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports Links
import { Link } from 'react-router-dom';
//imports icons
import {FaWindowClose } from 'react-icons/fa';

//our component
const ModalFrame = ({close, children}) => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);
  return (
    <div className={`p-3 ${theme.theme.body}`}>
      <Link className='float-end' onClick={close}><FaWindowClose size={28}/></Link>
      <div className='pt-4 '>
        {children}
      </div>
    </div>
  )
}

//export our component
export default ModalFrame