// File: src\components\dynamics\menuItems.js
// Author: Harley McDonald
// Version 1.0
// This file holds our navigation links as an array.

//an array containing all navigation items, their links and information on when to render them
export const menuItems = [
  {
    title: "Home",
    url: "/",
    base: true
  },
  {
    title: "Info",
    submenu: [
      {
        title: "About",
        url: "/info/about",
        base: true
      },
      {
        title: "Experiences",
        url: "/info/experience",
        base: true
      },
      {
        title: "Interests",
        url: "/info/interests",
        base: true
      },
      {
        title: "Projects",
        url: "/info/project",
        base: true
      }
    ],
    base: true
  },
  {
    title: "User",
    submenu: [
      {
        title: "Profile",
        url: "/users/profile",
        login: true,
        base: false
      },
      {
        title: "Manage Users",
        url: "/manage/users",
        login: true,
        admin: true,
        base: false
      }
    ],
    login: true,
    base: false
  },
  {
    title: "Website Management",
    submenu: [
      {
        title: "Interests Management",
        url: "/manage/interests",
        login: true,
        admin: true,
        base: false
      },
      {
        title: "Project Management",
        url: "/manage/project",
        login: true,
        admin: true,
        base: false
      },
    ],
    login: true,
    admin: true,
    base: false
  },
  {
    title: "Login",
    url: "users/login",
    login: false,
    base: false

  },
  {
    title: "Register",
    url: "users/register",
    login: false,
    base: false
  },
  {
    title: "Logout",
    url: "/",
    login: true,
    base: false
  }
]