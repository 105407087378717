// File: /src/components/layout/Alert.js
// Author: Harley McDonald
// Version 1.0
// This component will display messages in the UI.
// The component will take in an array of errors/messages and display them to the screen.

//importing react and important components
import React from 'react';
import { connect } from 'react-redux';
//imports proptypes checking
import PropTypes from 'prop-types';

// The component takes in an array of alerts
const Alert = ({ alerts }) => {
  // Check that we have an alerts array, and that its length is greater then 0.
  // Then map through each alerts.
  return (alerts !== null && alerts.length > 0 && alerts.map( alert =>
    // display each alert with the alert class, alert type and the message. 
    // The alert-type will determine the colour of the alert.
    (
      <div key={alert.id} className={`mt-3 alert alert-${alert.alertType}`} >
        {alert.msg}
      </div>
    ))
  );
};

//checks prop types
Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

// Map state to props.
const mapStateToProps = state => ({
  alerts: state.alert
});

//exports our component
export default connect(mapStateToProps)(Alert);