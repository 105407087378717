// File: src\components\dynamics\ThemeContextWrapper.js
// Author: Harley McDonald
// Version 1.0
// This file holds our the component for implementing our theme context.

//importing react and important components
import React, { useState, useEffect } from 'react';
//imports our theme context and themes for use
import { ThemeContext, themes } from './ThemeContext';

//our theme context component
export default function ThemeContextWrapper(props) {
  //instances the theme and defaults it to dark
  const [theme, setTheme] = useState(themes.dark);

  //a function to change the theme state
  function changeTheme(theme) {
    setTheme(theme);
  }

  //calls an anynomous function to check theme and implements changes to body class
  useEffect(() => {
    switch (theme) {
      case themes.light:
        document.body.classList.add(`${themes.light.body}`);
        document.body.classList.add(`body-light`);
        document.body.classList.remove(`${themes.dark.body}`);
        document.body.classList.remove(`body-dark`);
        break;
      case themes.dark:
        document.body.classList.add(`${themes.dark.body}`);
        document.body.classList.add(`body-dark`);
        document.body.classList.remove(`${themes.light.body}`);
        document.body.classList.remove(`body-light`);
        break;
      default:
        break;
    }
  }, [theme]);

  //our component layout
  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}