// File: src/reducers/authReducer.js
// Author: Harley McDonald
// Version 1.0
// This file holdes the states and reducer that will deal with auth.

// import types
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  GET_SELF_ID,
  LOGIN_FAIL,
  USER_LOADED,
  ADMIN_LOADED,
  AUTH_ERROR,
  LOGOUT,
  ACCOUNT_DELETED
} from '../actions/types';

// Create the intial state for our auth reducer.
const intialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: localStorage.getItem('token') ? true : false,
  isAdmin: false,
  loading: true,
  user: null,
  errors: {}
};

// Create the auth reducer.
// The reducer takes in the state and an action.
export default function authReducer(state = intialState, action){
  switch(action.type){
    case USER_LOADED:
      return{
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload
      }
    case ADMIN_LOADED:
    return{
      ...state,
      loading: false,
      isAdmin: action.payload
    }
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('isAdmin', action.payload.isAdmin);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isAdmin: action.payload.isAdmin,
        loading: false
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('isAdmin');
      return {
        ...state,
        token: null,
        isAuthenticated: undefined,
        isAdmin: undefined,
        user: null,
        selfId: undefined
      }
    case ACCOUNT_DELETED:
      localStorage.removeItem('token');
      localStorage.removeItem('isAdmin');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isAdmin: false,
        loading: false,
        selfId: undefined,
        user: null
      }
    case GET_SELF_ID:
      return{
        ...state,
        loading: false,
        selfId: action.payload
      }
    default:
      return state;
  }
}