// File: src/reducers/projectReducer.js
// Author: Harley McDonald
// Version 1.0
// This file holds all our state and reducers that will deal with project.

// Import our types.
import {
  GET_PROJECT,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  DEL_ERROR
} from '../actions/types';

// Create our initial state object.
const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: localStorage.getItem('token')? true : false,
  project: [],
  projectPost: {},
  loading: true,
  errors: {}
};

// Create our projectReducer to change our state.
// A reducer takes in the state and an action.
// This will update the state based on the action dispatched.
export default function projectReducer(state = initialState, action){
  switch(action.type){
    // The cade to get all projects
    case GET_PROJECT:
      return {
        ...state,
        project: action.payload || [],
        loading: false
        // returning the current state plus the new projectList and changing the loading.
      }
    // case for adding project
    case ADD_PROJECT:
      return{
        // Geting everything in the state
        ...state,
        // Adding the new project (which is in our action.payload) at the start of our projectList array.
        project: [action.payload, ...state.project],
        loading: true
      };
      case UPDATE_PROJECT:
        return{
          ...state,
          project: state.project.map (
            item => item.projectId === action.payload.projectId ?
            (item = action.payload) : item
          ),
          loading: true
        };
    // a case to delete project
    case DELETE_PROJECT:
      return {
        ...state,
        project: state.project.filter(
          item => item.projectId !== action.payload
        ),
        loading: false
      };
    // Case for DEL_ERROR
    case DEL_ERROR:
      return{
        ...state,
        errors: action.payload
      };
    default:
      return state;
  } // End of Switch
} // End of export