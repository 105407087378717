// File: client\src\components\manangement\EditProject.js
// Author: Harley McDonald
// Version 1.0
// This file holds our project management page.

//importing react and important components
import React, { useEffect, useState, useContext} from 'react';
//imports our theme context
import { ThemeContext } from '../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
// Import connect from react-redux.
import { connect } from 'react-redux';
// import our singleProject component.
import SingleEditProject from './SingleEditProject';
// import our getProject function from the actions file
import { getProject, addProject } from '../../actions/projectActions';
//imports a component for creating modals
import ReactModal from 'react-modal';
//imports a component for framing modal content
import ModalFrame from '../layout/ModalFrame';
// import navigation component and function
import {Navigate, useNavigate} from 'react-router-dom';
//imports an icon
import { FaPlus } from 'react-icons/fa';

// Props destructuring.
const ManageProject = ({ loading, isAdmin, project, getProject, addProject}) => {
  
  //variable for alternating component colours
  var i = 1;

  //grabs our theme context from the surface level component
  const theme =  useContext(ThemeContext);
  
  // Create our navigate var.
  const navigate = useNavigate();

  // Create a useEffect to load in the experience.
  // This will use our getProject action.
  useEffect(() => {
    getProject();
  }, [getProject]);

  //Our toggle for displaying our modal form
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  //adds our form data to the state
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    lang: "",
    time: "",
    desc: "",
    hiLit: "",
    satisf: "",
    selfRat: "",
    takeAway: "",
    errors: {}
  });

  // Destructure the state.
  const { title, type, lang, time, desc, hiLit, satisf, selfRat, takeAway, errors } = formData;

  // On Change
  const onChange = e => setFormData(
    { ...formData, [e.target.name]: e.target.value }
  );

  // onSumbit function
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log('On sumbit - ManageProject page');

    // Error checking
    var errorChk = false;
    var err = {};
    
    // Check errors title
    if (title === ''){
      err.title = 'Project Title is required ';
      errorChk = true;
    }
    
    // Check errors type
    if (type === ''){
      err.type = 'Project Type is required ';
      errorChk = true;
    }
    
    // Check errors base
    if (lang === ''){
      err.lang = 'Project Base is required ';
      errorChk = true;
    }
    
    // Check errors duration
    if (time === ''){
      err.time = 'Project Duration is required ';
      errorChk = true;
    }
    
    // Check errors description
    if (desc === ''){
      err.desc = 'Description is required ';
      errorChk = true;
    }

    // Check errors highlight
    if (hiLit === ''){
      err.hiLit = 'Highlight of project is required';
      errorChk = true;
    }

    // Check errors satisfaction
    if (satisf === ''){
      err.satisf = 'Satisfaction rating of project is required';
      errorChk = true;
    }

    // Check errors self rating
    if (selfRat === ''){
      err.selfRat = 'Self Rating of work on project is required';
      errorChk = true;
    }

    // Check errors lesson learned
    if (takeAway === ''){
      err.takeAway = 'Lesson learned from project is required';
      errorChk = true;
    }

    //returns all errors
    if(errorChk){
      setFormData({...formData, errors});
      return;
    }
    // Create the user object
    const projectSub = { title, type, lang, time, desc, hiLit, satisf, selfRat, takeAway};

    // Call the register function
    await addProject(projectSub);

    // redirect the browser
    navigate('/info/project');
    
    //closes the modal
    setModalOpen(false);
  }

  //checks if the user is an admin and redirects them if not
  if(isAdmin !== true){
    return (<Navigate to='/'/>)
  }
  
  //displays loading while loading and our page when not
  return (loading) ? (
    <h2 className={`text-center ${theme.theme.title} p-3`}> Loading... </h2>
  ) : (
    <div className='mt-3'>
      <h2 className={`text-center ${theme.theme.title} p-3`}>Project Management</h2>
      <FaPlus className='text-success ms-3 d-inline-block' onClick={toggleModal} size={60} title="Add Project"/>
      <ReactModal isOpen={modalOpen} onRequestClose={toggleModal} className={`${theme.theme.body} position-absolute top-50 start-50 translate-middle w-50`}>
        <ModalFrame close={toggleModal}>
          <div>
          <form onSubmit={e => onSubmit(e)}>
          <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='title'>Project Title</label>
              <input 
                type='text'
                className={`form-control ${errors.title ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='title'
                placeholder='Please type out the title of the project'
                name='title'
                value={title} 
                onChange={e => onChange(e)}
              />
              {errors.title && <div className='invalid-experience'>{errors.title}</div>}
            </div>

            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='type'>Type</label>
              <input 
                type='text'
                className={`form-control ${errors.type ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='type'
                placeholder='Please type the type of project, ect Professional'
                name='type'
                value={type} 
                onChange={e => onChange(e)}
              />
              {errors.type && <div className='invalid-experience'>{errors.type}</div>}
            </div>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='lang'>Project Components</label>
              <input 
                type='text'
                className={`form-control ${errors.lang ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='lang'
                placeholder='Please type the list of components that made up the project, ect languages, frameworks'
                name='lang'
                value={lang} 
                onChange={e => onChange(e)}
              />
              {errors.lang && <div className='invalid-experience'>{errors.lang}</div>}
            </div>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='time'>Project Duration</label>
              <input 
                type='text'
                className={`form-control ${errors.time ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='time'
                placeholder='Please type how long you worked on the project in years'
                name='time'
                value={time} 
                onChange={e => onChange(e)}
              />
              {errors.time && <div className='invalid-experience'>{errors.time}</div>}
            </div>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='desc'>Description</label>
              <textarea
                className={`form-control ${errors.desc ? "is-invalid" : 'is-valid'} ${theme.theme.compA}`}
                id='desc'
                placeholder='Please type out a description of the project'
                name='desc'
                value={desc} 
                onChange={e => onChange(e)}
              />
              {errors.desc && <div className='invalid-experience'>{errors.desc}</div>}
            </div>

            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='hiLit'>Highlight</label>
              <textarea
                className={`form-control ${errors.hiLit ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='hiLit'
                placeholder='Please describe a highlight of the project'
                name='hiLit'
                value={hiLit} 
                onChange={e => onChange(e)}
              />
              {errors.hiLit && <div className='invalid-experience'>{errors.hiLit}</div>}
            </div>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='satisf'>Satisfaction</label>
              <input 
                type='text'
                className={`form-control ${errors.satisf ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='satisf'
                placeholder='Please type your level of satisfaction with the project'
                name='satisf'
                value={satisf} 
                onChange={e => onChange(e)}
              />
              {errors.satisf && <div className='invalid-experience'>{errors.satisf}</div>}
            </div>
            
            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='selfRat'>Self Rating</label>
              <input 
                type='text'
                className={`form-control ${errors.selfRat ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='selfRat'
                placeholder='Please give yourself a rating on your work throughout this project out of 5'
                name='selfRat'
                value={selfRat} 
                onChange={e => onChange(e)}
              />
              {errors.selfRat && <div className='invalid-experience'>{errors.selfRat}</div>}
            </div>

            <div className='mb-3'>
              <label className={`${theme.theme.text} `} htmlFor='takeAway'>Takeaway</label>
              <textarea
                className={`form-control ${errors.takeAway ? "is-invalid" : 'is-valid'}  ${theme.theme.compA}`}
                id='takeAway'
                placeholder='Please describe a lesson you learned from this project.'
                name='takeAway'
                value={takeAway} 
                onChange={e => onChange(e)}
              />
              {errors.takeAway && <div className='invalid-experience'>{errors.takeAway}</div>}
            </div>
            <div className='d-grid gap-2'>
              <input type='submit' value='Submit' className={`${theme.theme.btn}`}/>
            </div>
          </form>
          </div>
        </ModalFrame>
      </ReactModal>
      <div className='m-5'>
        {
          project.map(projectPost => {
            //loops through project posts and displays them
            i++;
            return <SingleEditProject project={projectPost} i={i}/>
          })
        }
      </div>
    </div>
  )
}

// Checks prop types
ManageProject.propTypes = {
  getProject: PropTypes.func.isRequired,
  addProject: PropTypes.func.isRequired,
  project: PropTypes.array.isRequired
};

// Define mapStateToProps.
const mapStateToProps = state => ({
  project: state.project.project,
  loading: state.project.loading,
  isAdmin: state.auth.isAdmin
});

//exports our component
export default connect(mapStateToProps, {getProject, addProject})(ManageProject);