// File: client\src\components\layout\header\Header.js
// Author: Harley McDonald
// Version 1.0
// This file holds our header.

//importing react and important components
import React, {useState, useContext} from 'react';
//imports our theme context
import { ThemeContext } from '../../dynamics/ThemeContext';
// Bring in connect
import { connect } from 'react-redux';
//imports our navigation components
import Hamburger from './Hamburger';
import Navbar from './Navbar';
//imports our banner picture
import banner from '../../../Assets/img/Banner.png';

// our component
const Header = () => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);
  //sets a bool to state to track hamburger menu opening
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen)
  }

  //returns the header
  return (
    <header>
      <img src={`${banner}`} alt={"Banner"} className='w-100'></img>
      <nav  className={`${theme.theme.bar} navbar navbar-expand-md navbar-dark bg-primary`}>
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <Navbar />
          </div>
          <div className='hamburger' onClick={toggleHamburger}>
            <Hamburger isOpen={hamburgerOpen}/>
          </div>
        </div>
      </nav>
    </header>
  )
}

//exports our component
export default connect(null)(Header);