// File: client\src\components\manangement\users\SingleUser.js
// Author: Harley McDonald
// Version 1.0
// This file holds our user component.

//importing react and important components
import React, {useState, useContext} from 'react';
import { connect } from 'react-redux';
//imports our theme context
import { ThemeContext } from '../../dynamics/ThemeContext';
//imports proptypes checking
import PropTypes from 'prop-types';
//imports icons
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
//imports a component for creating modals and our frame
import ReactModal from 'react-modal';
import ModalFrame from '../../layout/ModalFrame';
// import delete and edit functions.
import { updateUser, deleteUser } from '../../../actions/userActions';

const SingleUser = ({ user, updateUser, deleteUser }) => {
  //grabs our theme context from the surface level component
const theme =  useContext(ThemeContext);

//destructures the user state
  const { userId, firstName, lastName, company, isAdmin } = user;
  
  //Our toggle for displaying our modal form
const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  //adds our form data to the state
  const [formData, setFormData] = useState({
    userId: userId,
    fFirstName: "",
    fLastName: "",
    fCompany: "",
    fIsAdmin: null,
    errors: {}
  });

  // Destructure the state.
  const { fFirstName, fLastName, fCompany, fIsAdmin, errors } = formData;

  // On Change
  const onChange = e => {
    e.target.name !== "fIsAdmin" ? setFormData({ ...formData, [e.target.name]: e.target.value })
    : setFormData({ ...formData, [e.target.name]: !e.target.value })
  }
  

  // onSumbit function
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log('On sumbit - ManageProject page');

    // Error checking
    var errorChk = false;
    var err = {};

    // Check errors first Name
    if (firstName === ''){
      err.firstName= 'First Name is required ';
      errorChk = true;
    }

    // Check errors last Name
    if (lastName === ''){
      err.lastName= 'Last Name is required ';
      errorChk = true;
    }
    
    // check for errors in the email field
    if (company === ''){
      err.company= 'Company is required ';
      errorChk = true;
    }

    //returns all errors
    if(errorChk){
      setFormData({...formData, errors});
      return;
    }
    // Create the user object
    const newU = { userId, firstName: fFirstName, lastName: fLastName, company:fCompany, isAdmin: fIsAdmin,};
    console.log(newU);
    // Call the register function
    await updateUser(newU);
  }
  return (
    <tr>
    <td className={`${theme.theme.text} `}>{userId}</td>
    <td className={`${theme.theme.text} `}>{firstName}</td>
    <td className={`${theme.theme.text} `}>{lastName}</td>
    <td className={`${theme.theme.text} `}>{user.email}</td>
    <td className={`${theme.theme.text} `}>{isAdmin ? 'Yes' : 'No'}</td>
    <td className='vertical-align-top'><img className='img-fluid m-2' src={company} alt={firstName} style={{ maxWidth: '64px', borderRadius: '50%' }} onError={({ currentTarget}) => {
              //checks if the company loaded and if not, displays a default
              currentTarget.onerror = null;
              currentTarget.src= "https://cdn-icons-png.flaticon.com/512/727/727399.png?w=1800&t=st=1688702835~exp=1688703435~hmac=d40e233fc71f38f24a1249eb5e2178f3899c2783ad5e8e08e9a707b04f1b4223";
            }} /> </td>
    <td>
      <FaPencilAlt className='text-primary p-1' onClick={toggleModal} style={{cursor: 'pointer' }} title="Edit"  size={35}/>
      <ReactModal isOpen={modalOpen} onRequestClose={toggleModal} className={`${theme.theme.body} position-absolute top-50 start-50 translate-middle w-50`}>
        <ModalFrame close={toggleModal}>
          <div>
          <form onSubmit={e => onSubmit(e)}>
            <div className={` mb-3`}>
              <input type="hidden" id="userId" name="userId" value={userId}></input>
              <label className={`${theme.theme.text} `}  htmlFor='fFirstName'>First Name</label>
              <input 
                type='text'
                className={`form-control ${errors.firstName ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='fFirstName'
                placeholder='First Name'
                name='fFirstName'
                defaultValue={`${fFirstName === "" ? firstName : fFirstName}`} 
                onChange={e => onChange(e)}
              />
              {errors.firstName && <div className='invalid-experience'>{errors.firstName}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='fLastName'>Last Name</label>
              <input 
                type='text'
                className={`form-control ${errors.lastName ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='fLastName'
                placeholder='Last Name'
                name='fLastName'
                defaultValue={`${fLastName === "" ? lastName : fLastName}`} 
                onChange={e => onChange(e)}
              />
              {errors.lastName && <div className='invalid-experience'>{errors.lastName}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='fCompany'>Company</label>
              <input
                className={`form-control ${errors.company ? "is-invalid" : 'is-valid'} ${theme.theme.compA} ${theme.theme.text}`}
                id='fCompany'
                placeholder='company URL'
                name='fCompany'
                defaultValue={`${fCompany === "" ? company : fCompany}`}
                onChange={e => onChange(e)}
              />
              {errors.company && <div className='invalid-experience'>{errors.company}</div>}
            </div>
            <div className='mb-3'>
              <label className={`${theme.theme.text} `}  htmlFor='fIsAdmin'>Is Admin?</label>
              <input
                type="checkbox"
                id='fIsAdmin'
                defaultChecked={fIsAdmin === null ? isAdmin : fIsAdmin}
                onChange={e => onChange(e)}
              />
              {errors.company && <div className='invalid-experience'>{errors.company}</div>}
            </div>
            <div className='d-grid gap-2'>
              <input type='submit' value='Submit' className={`${theme.theme.btn}`}/>
            </div>
          </form>
          </div>
        </ModalFrame>
      </ReactModal>
    </td>
    <td>
      <FaTimes className='text-danger p-1' size={40} onClick={e => deleteUser(userId)} style={{ cursor: 'pointer'}} title='Delete'/>
    </td>
  </tr>
  )
}

//checks our props
SingleUser.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired
}

//exports our component
export default connect(null, {updateUser, deleteUser})(SingleUser);