// File: client\src\components\layout\header\MenuItems.js
// Author: Harley McDonald
// Version 1.0
// This file holds our menu items.

//importing react and important components
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//imports proptypes checking
import PropTypes from 'prop-types';
//imports our sub menues
import Dropdown from './Dropdown';
// Bring in Link for our react-router-dom
import { logout } from '../../../actions/authActions';
//imports icons for our menu items
import NavIcon from './NavIcon';

//our commponent
const MenuItems = ({ items, isAuthenticated, isAdmin, logout }) => {
  //sets value to state for submenu opening
  const [dropdown, setDropdown] = useState(false);

  //double checks that is authenticated and is admin isn't unidentified
  if(isAuthenticated === undefined){isAuthenticated=false}
  if(isAdmin === undefined){isAdmin=false}


  //sets up our ref var
  let ref = useRef();

  //sets up functions for tracking hover on sub menus
  useEffect(() => {
    const handler = (event) => {
     if (dropdown && ref.current && !ref.current.contains(event.target)) {
      setDropdown(false);
     }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
     // Cleanup the event listener
     document.removeEventListener("mousedown", handler);
     document.removeEventListener("touchstart", handler);
    };
   }, [dropdown]);

   //opens dropdowns on mouse hover when window is above threshold
   const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
   };
   
   //closes dropdowns on mouse hover when window is above threshold
   const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
   };
   
   //closes dropdowns
   const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  //returns our component
  return (
    <li className="menu-items" ref={ref} onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={closeDropdown}>
      {
        //checks if user should see options
        items.submenu ? ((items.login === isAuthenticated && (items.admin === isAdmin || items.admin === undefined)) || (items.base) ? (
        <>
          <Link role="button" className="nav-link active" aria-current="page" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"} onClick={() => setDropdown((prev) => !prev)}>
          <NavIcon title={items.title}/>{" "}{items.title}{' '}
          </Link>
          <Dropdown submenus={items.submenu} dropdown={dropdown} isAdmin={isAdmin} isAuthenticated={isAuthenticated} />
        </>) : 
        (<></>)
        //checks if user should see options
      ) : ( (items.login === isAuthenticated && (items.admin === isAdmin || items.admin === undefined)) || (items.base) ?
        <Link className="nav-link active" aria-current="page" to={`${items.url}`} onClick={items.title === "Logout" ? (() => {logout();}): ("")}><NavIcon title={items.title}/>{" "} {items.title}</Link> : <></>
         )
      }
    </li>
  );
};

// Prop type checking
MenuItems.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAdmin: PropTypes.bool,
  logout: PropTypes.func.isRequired
};

// Define mapStateToProps.
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isAdmin: state.auth.isAdmin
});

//exports our component with needed
export default connect(mapStateToProps, {logout})(MenuItems);